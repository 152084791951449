.preview-page {


    .level-image{
        width: 100%;
        
        &.-desktop {
            display: block;
        }

        &.-mobile {
            display: none;
        }
    }
}


@media only screen and (max-width: theme.settings.breakpoints.xsmall) {

    .preview-page {
        .image{
            &.-desktop {
                display: none;
            }
            &.-mobile {
                display: block;
            }
        }

    }

}