.unit-type {

    //display: flex;
    //align-items: center;
    padding-right: rem(15px);

    > svg {
        width: rem(35px);
        height: rem(35px);
    }

}