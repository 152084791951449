.image-element {
    
    padding-bottom: rem(60px);
    
    > .image {
        min-width: 100%;
    }

    > .caption {
        padding-top: rem(10px);
    }
}