.editor-content {
    // dl {
    //     width: 100%;
    //     overflow: hidden;
    //     // background: #ff0;
    //     padding: 0;
    //     margin: 0
    //     clear: left;
    // }
    // dt {
    //     float: left;
    //     width: 25%;
    //     padding: 0;
    //     margin: 0;
    //     margin-bottom: 2px;
    //     margin-right: 2px;
    //     background-color:#fff;
    //     clear: left;
    // }
    // dd {
    //     float: left;
    //     width: 72%;
        
    //     background: #fff
    //     padding: 0;
    //     margin: 0
    // }
    

dl {
    display: flex;
    flex-flow: row wrap;
}
  dt {
    flex-basis: 30%;
    padding: 2px 4px;
    background: #333;
    // text-align: right;
    margin-right: 2px;
    margin-bottom: 2px;
    background-color: #fff;

    &.customer {
        margin-top: rem(16px);
    }
  }
  dd {
    flex-basis: 60%;
    flex-grow: 1;
    margin-bottom: 2px;
    padding: 2px 4px;
    background-color: #fff;
    //border-bottom: 1px solid #333;
    
    &.customer {
        margin-top: rem(16px);
    }
  }

    .form-component {
        .input {
            margin: 0;
        }
    }

    .autocomplete-component {
        border: 1px solid black;
    }


}

