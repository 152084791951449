.start-zikzakteaser {
    margin-bottom: rem(60px);
    .text {
        margin-bottom: theme.components.margin-box.medium;
    }
    
    .image {
        width: 100%;
    }

    .svg-icon {
        color: theme.colors.primary;
        width: 30px;
        height: 30px;
    }
    .point {
        line-height: 0;
    }

    @media only screen and (max-width: theme.settings.breakpoints.xsmall) { 
        margin-bottom: rem(40px);
        .image {
            //margin-right:0px;
        }
    }

}