.meta-box {

    padding: rem(50px) 0;

    &.-editor {
        .task-menu {
            >.add {
                display:none;
            }
        }
    }

    @media only screen and (max-width: theme.settings.breakpoints.small) {
        padding: rem(40px) 0;

        &.-editor {
            display:none;
        }
    }


}