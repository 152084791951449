.state-symbol {

    &.-redeemed {
        background-color: theme.colors.green;
    }
    &.-unredeemed {
        background-color: theme.colors.gray-1;
    }

    &.-deactivated {
        background-color: theme.colors.red;
    }

}