.dropdown-filter {

    
    width: 100%;
    display: none;
    margin: rem(10px) auto;
    
    >.description {
        margin-bottom:rem(10px);

        a {
            color: theme.colors.primary;
            // border-bottom: 1px solid theme.colors.primary;
        }
        a:hover {

            color: theme.colors.black;
            // border-bottom: 1px solid theme.colors.black;
        }
    }

    >.drop-down {

        >.toggle {
            border: 1px solid theme.colors.gray-1;
            padding: rem(5px);
        }
        &.-open {
            >.toggle {
                border-bottom: none;
            }
        }

        >.drop-down-menu {
            background-color: #fff;
            border: 1px solid theme.colors.gray-1;
            border-top: none;
            width: 100%;
        }
    }

    .value {
        overflow:hidden;
        padding: rem(5px);

        &:hover {
            color: theme.colors.primary;
        }

    }
    .svg-icon {
        color: theme.colors.primary;

        &.-toggle {
            color: theme.colors.gray-1;
            width: rem(10px);
            height: rem(6px);
        }
    }


    @media only screen and (max-width: theme.settings.breakpoints.small) {
        display: block;

        >.drop-down {
            display: block;
            margin: 0 auto;
            >.toggle {
                display:block;
            }
        }
    }
}