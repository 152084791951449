.link-button {

    > a {
        
        &:hover {
            color: theme.colors.primary;
        }

    } 

}