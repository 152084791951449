.unit-infobox {

    padding: rem(15px);
    height: rem(202px);
    

    > .meta {
        padding-bottom: rem(15px);
        align-items:center;

        >.slugcode {
            text-align:right;
            color: theme.colors.red;
            >.number {
                color: #000;
            }
        }
        >.pagenumber {
            text-align:right;
            //font-size: 16px;
            color: theme.colors.gray-1;
            //font-weight: bold;
        }

    }

}