.button-back {

    height: rem(40px);
    //padding: rem(10px) rem(15px);
    background-color: transparent;
    color: theme.colors.gray-1;
    text-transform: uppercase;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        color: theme.colors.primary;
    }

    > .svg-icon {
        fill: theme.colors.primary;
        margin-top: rem(7px);
    }

}

