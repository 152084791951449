@font-face{
    font-family:"Glober-Regular";
    src:url("../Fonts/Glober-Regular.eot") format("eot"), 
        url("../Fonts/Glober-Regular.woff") format("woff"),
        url("../Fonts/Glober-Regular.woff2") format("woff2"),
        url("../Fonts/Glober-Regular.ttf") format("ttf")
}

@font-face{
    font-family:"Glober-Semi-Bold";
    src:url("../Fonts/Glober-Semi-Bold.eot") format("eot"), 
        url("../Fonts/Glober-Semi-Bold.woff") format("woff"),
        url("../Fonts/Glober-Semi-Bold.woff2") format("woff2"),
        url("../Fonts/Glober-Semi-Bold.ttf") format("ttf")
}

@font-face{
    font-family:"Glober-Bold";
    src:url("../Fonts/Glober-Bold.eot") format("eot"), 
        url("../Fonts/Glober-Bold.woff") format("woff"),
        url("../Fonts/Glober-Bold.woff2") format("woff2"),
        url("../Fonts/Glober-Bold.ttf") format("ttf")
}

@font-face{
    font-family:"Glober-Extra-Bold";
    src:url("../Fonts/Glober-Extra-Bold.eot") format("eot"), 
        url("../Fonts/Glober-Extra-Bold.woff") format("woff"),
        url("../Fonts/Glober-Extra-Bold.woff2") format("woff2"),
        url("../Fonts/Glober-Extra-Bold.ttf") format("ttf")
}