.pagination {

    list-style: none;
    text-align: center;
    margin-bottom: 10px;

    li {
        display: inline;
        padding: rem(5px);
    }
}