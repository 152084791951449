body {
    text-rendering: optimizeLegibility;
}


h1, h2, h3, h4, p {
    &.-error {
        color: theme.colors.red;
    }
}
h1.magazine-title {

    font-family: 'Glober-Extra-Bold';
    color: theme.colors.white;
    font-size: rem(80px);
    line-height: rem(90px);
    letter-spacing: rem(2px);
    text-shadow: 0 1px 5px #00000;
    text-rendering: optimizeLegibility;
}

h1 {

    font-family: 'Glober-Extra-Bold';
    color: theme.colors.black;
    font-size: rem(50px);
    line-height: rem(62px);
    letter-spacing: rem(1px);
    text-rendering: optimizeLegibility;

}

h2 {

    font-family: 'Glober-Extra-Bold';
    color: theme.colors.black;
    font-size: rem(30px);
    line-height: rem(48px);
    padding-bottom: rem(10px);

}

h3 {

    font-family: 'Glober-Extra-Bold';
    color: theme.colors.black;
    font-size: rem(22px);
    line-height: rem(32px);
    padding-bottom: rem(10px);
    text-rendering: optimizeLegibility;

}

h4 {
    font-family: 'Glober-Extra-Bold';
    color: theme.colors.black;
    font-size: rem(20px);
    line-height: rem(30px);
}



.paragraph-element a {
    // border-bottom: 1px solid theme.colors.primary;
    font-weight: bold;
    color: theme.colors.primary;
    font-size: rem(20px);
    line-height: rem(30px);
    text-rendering: optimizeLegibility;
    &:hover {
        color: theme.colors.gray-1;
    }
}

a {
    color: theme.colors.primary;
    // font-weight: bold;
    &:hover {
        color: theme.colors.gray-1;
    }
}


.p-normal, ol, ul, .text-style {
    font-family: 'Glober-Regular';
    color: theme.colors.black;
    font-size: rem(20px);
    line-height: rem(30px);
    text-rendering: optimizeLegibility;
}


ul, ol {
    margin-left: 1.7rem;
}

p.-bold, .p-bold {
    font-family: 'Glober-Extra-Bold';
    color: theme.colors.black;
    font-size: rem(16px);
    line-height: rem(23px);
}

p.-big, .p-big {

    font-family: 'Glober-Regular';
    color: theme.colors.black;
    font-size: rem(24px);
    line-height: rem(32px);
}

p.-small, .p-small {

    font-family: 'Glober-Regular';
    color: theme.colors.black;
    font-size: rem(15px);
    line-height: rem(21px);
    padding-bottom: rem(60px);

}

.footer-text {

    font-family: 'Glober-Semi-Bold';
    color: theme.colors.black;
    font-size: rem(13px);
    line-height: rem(20px);
    text-rendering: optimizeLegibility;

}

.footer-heading {

    color: theme.colors.white;
    font-family: 'Glober-Extra-Bold';
    font-size: rem(15px);
    line-height: rem(24px);
    padding-bottom: rem(15px);
    text-rendering: optimizeLegibility;

}

input, textarea {

    font-family: 'Glober-Semi-Bold';
    color: theme.colors.gray-1;
    font-size: rem(16px);
    line-height: rem(26px);
    text-rendering: optimizeLegibility;
    &:active {
        color: theme.colors.green;
    }

    &.-disabled {
        color: theme.colors.gray-2;
    }

}

.tag-text {

    font-family: 'Glober-Bold';
    color: theme.colors.gray-1;
    font-size: rem(16px);
    line-height: rem(26px);
    text-rendering: optimizeLegibility;
}

.label-text {

    font-family: 'Glober-Extra-Bold';
    color: theme.colors.black;
    font-size: rem(16px);
    line-height: rem(24px);
    text-rendering: optimizeLegibility;
    &.-library {
        font-family: 'Glober-Regular';
    }

}

.filter-text {

    font-family: 'Glober-Bold';
    color: theme.colors.gray-1;
    font-size: rem(16px);
    line-height: rem(24px);
    text-rendering: optimizeLegibility;
}

.navigation-text {
    font-family: 'Glober-Bold';
    color: theme.colors.black;
    font-size: rem(16px);
    line-height: rem(24px);
    text-transform: uppercase;
    letter-spacing: rem(2px);
    text-rendering: optimizeLegibility;

}

.caption-text {
    font-family: 'Glober-Extra-Bold';
    color: theme.colors.black;
    font-size: rem(14px);
    line-height: rem(21px);
    text-align: center;
    text-rendering: optimizeLegibility;

}

.link-button, .link-button a {

    font-family: 'Glober-Bold';
    color: theme.colors.gray-1;
    text-transform: uppercase;
    font-size: rem(16px);
    line-height: rem(23px);
    letter-spacing: rem(2px);
    text-rendering: optimizeLegibility;

}

.button-text {
    
    font-family: 'Glober-Extra-Bold';
    font-size: rem(16px);
    line-height: rem(23px);
    letter-spacing: rem(2px);
    text-rendering: optimizeLegibility;

}





@media only screen and (max-width: theme.settings.breakpoints.small) { 
    h1.magazine-title {
        font-size: rem(40px);
        line-height: rem(48px);
        letter-spacing: rem(1px);
    }

    h1 {
        font-size: rem(36px);
        line-height: rem(46px);
        letter-spacing: rem(0.7px);
    }

    h2 {
        font-size: rem(26px);
        line-height: rem(36px);
        padding-bottom: rem(8px);
    }

    h3 {
        font-size: rem(20px);
        line-height: rem(28px);
        padding-bottom: rem(8px);
    }

    h4 {
        font-size: rem(18px);
        line-height: rem(26px);
    }

    .p-normal, ol, ul, .text-style {
        font-size: rem(18px);
        line-height: rem(26px);
    }

    ul, ol {
        margin-left: 1.7rem;
    }

    p.-big, .p-big {
        font-size: rem(20px);
        line-height: rem(30px);
    }


    .paragraph-element a {
        font-size: rem(15px);
        line-height: rem(21px);
    }


}

