

.feedback-form {
    .autocomplete >.value {
        cursor: pointer;
        padding-top: 5px;
        border-top: 1px solid theme.colors.gray-2;
    }
}


