.start-teaser {
    margin-bottom: rem(60px);
    //padding-bottom: rem(40px);

    .download {
        .svg-icon {
            width: 17px;
            height: 14px;
        }
        .text {
            	height: rem(23px);
                color: theme.colors.gray-1;	
                font-family: Glober-Bold;
                font-size: rem(16px);
                letter-spacing: rem(2px);
                line-height: rem(23px);
        }
        a:hover {
            .text {
                color: theme.colors.green;
            }
        }
    }

     .cover {
        .image-container {
            position: relative;
            display: block;

            .preview {
                    display:none;
                    color: theme.colors.green;
                    position: absolute;
                    transform: translate(-50%, -50%);
                    z-index: 10;
                    left: 50%;
                    top: 50%;
                    text-align:center;
                    .preview-icon {
                        width: 60px;
                        height: 60px;
                    }
                    .text {
                        color: theme.colors.green;
                        font-size: rem(16px);
                        font-weight: bold;
                    }
            }

            .image {
                width: 100%;
            }

            &:hover {
                .preview {
                    display: block;
                }
                .image {
                    box-shadow: 0px 2px 16px rgba(107, 107, 107, 0.50)
                    opacity: 0.5;
                    transition: box-shadow 0.3s, opacity 0.3s;
                }
            }
        }
    }




     .grid-layout.-gutter > .item.infos {
        padding-left: 100px;
        > .date {
        margin-bottom: rem(15px);
        }

        > .title {
            margin-bottom: rem(15px);
        }
        
        > .description {

            a {
                color: theme.colors.primary;
                text-rendering: optimizeLegibility;
                font-weight: bold;
                    &:hover {
                        color: theme.colors.gray-1;
                    }
            }
        }

        .link-button {
            &:hover {
                color: theme.colors.green;
            }

            .svg-icon {
                width: rem(23px);
                height: rem(23px);
                color:  theme.colors.primary;
                &.-right {
                    transform: rotate(180deg);
                }
            }
        }

        >.cart-component {
            display: inline-block;
            width: 150px;
        }
        
        >.buttons >.button-general {
            margin-top: rem(30px);
        }

    }




    @media only screen and (max-width: theme.settings.breakpoints.small) {
         .cover {
            text-align: center;

            .image-container {
                
                .preview {
                    display:inline-block;
                }

                .image {
                    width: 80%;
                    display: inline-block;
                    margin-bottom: rem(15px);
                    box-shadow: 0px 2px 16px rgba(107, 107, 107, 0.50)
                    opacity: 0.5;
                    // transition: box-shadow 0.3s, opacity 0.3s;
                }

            }
        }
        .grid-layout.-gutter > .item.infos {
            padding-left: 0px;
        }
        
        
    }

}