.unit-preview {

    box-shadow: 0 2px 6px 0 rgba(107,107,107,0.20);
    border-radius: 2px;
    transition: transform .2s, box-shadow .2s;
    width: 319px;
    
    position: relative;

    &:hover {
        box-shadow: 0 2px 16px 1px rgba(107,107,107,0.30);

        &.-disabled {
            box-shadow: 0 2px 6px 0 rgba(107,107,107,0.20);
            transform: none;
        }
    }

    &.-enabled {
         cursor: pointer;
     }
    
    &.-disabled > .image-container, &.-disabled > .download, &.-disabled > .unit-infobox   {
        // -webkit-filter: grayscale(100%);
        // -webkit-filter: grayscale(1);
        // filter: grayscale(100%);
        
        // filter: url('/images/gray.svg#grayscale');
        // filter: gray;

        opacity: 0.2;
    }

    &.-selected {
        border: 2px solid theme.colors.green;
    }

    >.image-container {
        
        position:relative; 
        
        > .image {
            height: rem(180px);
            background-size: cover;
            background-position: center;
        }
        
        >.subject {
            width: 50%;
            text-align: center;
            background-color: green;
            
            transform: translate(50%, -50%);
        }

    }

    > .download {

        padding: rem(10px) rem(15px);
        display: flex;
        align-items: center;
        border-top: 1px solid theme.colors.gray-3;

        &.-disabled {
            cursor: default;
        }

        &.-empty {
            border-top: none;
            min-height: rem(45px);
            max-height: rem(45px);
        }

        > a {
            display: flex;
            align-items: center;
        }

    }

    >.login-required {
        display: none;
    }

    >.license-required, &.-disabled.-auth >.login-required {
        display: none;
    }
    

    &.-disabled >.login-required, &.-disabled.-auth >.license-required {
        display:block;
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        padding: rem(20px) 0;
        background-color: #fff;
        text-align: center;
        
        >.text {
            margin-bottom:rem(15px);
        }

    }

}

@media only screen and (max-width: theme.settings.breakpoints.medium) { 
    .unit-preview {
        width: 100%;

    }
}



@media only screen and (max-width: theme.settings.breakpoints.small) { 
    .unit-preview {
        width: 100%;

    }
}