.add-license {
    // font-size: rem(40px);

    >.input {
        padding: 5px;
        font-size: rem(30px);
        width: rem(100px);
    }

    .button-general {
        line-height: 0.8px;
    }

}