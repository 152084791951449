.contents-table {
    .element:nth-child(even) {
         flex-direction: row-reverse; 
    }

    .element {
        margin-bottom: rem(36px);

        .image {
            width: 100%;
        }
        .page-row {
            align-items: center;
        }
        .pagenumber {
            color: theme.colors.primary;
            font-family: Glober-Extra-Bold;
            line-height: rem(62px);
            font-size: rem(50px);
            letter-spacing: rem(1px);
        }
    }
}