.navigation-main {
    
    &.-transparent {
        background-color: rgba(0, 0, 0, 0.0)
        box-shadow: none;

        .navigation-link {
            color: #fff;
            > .icon svg {
                color: #fff;
            }
        }

        .button-secondary {
            background-color: transparent;
            border: 2px solid #fff;
            color: #fff;
        }
        .zikzak-logo-white {
            display: block;
        }
        .zikzak-logo {
            display: none;
        }

        
        .mobilenavigation-menu{
            .navigation-link {
                color: #000;

                .button-secondary {
                    &:hover {
                        background-color: theme.colors.green;
                        color: #fff;
                    }
                    background-color: #fff;
                    border: 2px solid theme.colors.green;
                    color: theme.colors.green;
                }

                &:hover {
                    color: theme.colors.green;
                }
                
                > .icon svg {
                    color:theme.colors.green;
                }
            }
        }
    }

    .zikzak-logo-white {
        display: none;
    }



    background-color: #fff;
    padding: rem(10px) rem(30px) rem(10px) rem(30px);
    box-shadow: 0 2px 8px 0 rgba(38,38,43,0.10);

    z-index: 75;

    .-right {
        text-align:right;
    }

    &.-schoolpage {
        .navigation-mainheader, .mobilenavigation-mainheader {
            display:none;
        }
    }

    &.-overview, &.-startpage, &.-schoolpage {

        box-shadow: none;

        .mobilenavigation-menu {
            
            box-shadow: none;
            border-left: 1px solid theme.colors.gray-2;
            border-bottom: 1px solid theme.colors.gray-2;

        }

    }

    @media only screen and (max-width: theme.settings.breakpoints.medium) {
        
        padding: rem(10px) rem(20px) rem(10px) rem(30px);

        &.-overview, &.-startpage, &.-schoolpage { 
            border-bottom: 1px solid theme.colors.gray-2;
        }

    }
}