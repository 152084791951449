.level-box{

    display: flex;
    align-items: center;
    
    >.box {
        width: 20px;
        height: 20px;
        margin-right: rem(5px);
        border: 2px solid theme.colors.secondary;
        display: inline-block;

        &.-filled {
            background-color:theme.colors.secondary;
        }
    }
}