
.start-pointnavigation {
    
    &.-hidden {
        display: none;
    }
    
    text-align: center;
    width: 100px;
    display: inline-block;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 0%)

    .point {
        display: inline-block;
        cursor: pointer;
        margin: 5px;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        //border: 1px solid #66c5ef;
        background-color: theme.colors.gray-4;

        &.-active {
            background-color: theme.colors.gray-2;
        }
    }


}