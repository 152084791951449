.start-arrownavigation {


    .svg-icon.-right {
        transform: rotate(180deg);
    }

    .previous, .next {
        display: none;
        cursor:pointer;
        font-size: rem(80px);
        font-weight: bold;
        color: theme.colors.primary;
        
    }


    @media only screen and (max-width: theme.settings.breakpoints.medium) { 

        display: flex;
        flex-direction: row;
        width: 100%;
        
        .previous, .next {
            display: block;
            transform: translate(0%, 160px);
        }

        .previous, .next, .elements {
            flex: 1 1 auto;
            text-align: center;
        }

    }



}