.element-layout {
        max-width:rem(1000px);
        width: 100%; /* IE11 fix */
        margin-right: auto;
        margin-left: auto;
        padding: 0 1rem;

        &.-small {
                max-width: rem(800px);
        }

        &.-full {
                width: 100%;
                max-width: 100%;
        }

}