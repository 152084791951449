.task-response {


    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(0,0,0, 0.7);
    padding: rem(100px);

    a.break-text {
        color: #000;
        &:hover {

        }
    }

    >.content {
        background-color: white;
        padding: rem(20px);
        position: relative;
        >.close {
            top: rem(16px);
            right: rem(16px)
        }



    }

    @media only screen and (max-width: theme.settings.breakpoints.small) {
        
        
        .task-link {
            
            .svg-icon{
                display:none;
            }
        }
    }

    @media only screen and (max-width: theme.settings.breakpoints.xsmall) {
        padding: rem(20px);
        .content {
            .task-link {
                background-color: #fff;
                .button {
                    text-align:center;
                }
                .svg-icon, .break-text{
                    display:none;
                }
            }
        }
    }


}

