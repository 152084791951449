.startpage-content {

    padding-top: rem(64px);

    >.subheader {
        background-color: theme.colors.yellow;
        .award {
            max-width: 1100px;
            margin: 0 auto;
            .container {
                margin-left: 16px
            }
            .image {
                display: inline-block;
            }
            .pokal {
                height: 50px;
                margin-right: 32px;
            }
            .worlddidac {
                height: 32px;
                margin-right: 16px;
            }
            .text {
                font-size: 22px;
                line-height: 30px;
                font-family: 'Glober-Semi-Bold';
            }
        }
    }

    .intro {
        background-color: theme.colors.gray-3;
        padding-top: rem(100px);
     
    }

    .video-element {
        max-width: 62.5rem;
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        padding: 0 1rem;

        margin-bottom: 3.75rem;
    }


}


@media only screen and (max-width: theme.settings.breakpoints.small) {
    .startpage-content {

        >.subheader {

            .award {
                display: block;
                .container {
                    margin-bottom: 16px
                    margin-left: 16px
                }
                .text {
                    font-size: 14px;
                    line-height: 20px;
                    font-family: 'Glober-Semi-Bold';
                    margin-left: 16px
                }
            }
        }

        .video-element {
            margin-bottom: 2.5rem;
        }
    }

}