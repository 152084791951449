.unitpage-metacontainer {

    padding: rem(22px) 0;
    margin-bottom: rem(30px);
    border-top: 1px solid theme.colors.gray-2;
    border-bottom: 1px solid theme.colors.gray-2;

    

    > .levelbox-container { 
        padding-right: rem(30px);
        display: flex;
        align-items: center;

    }

    > .slug { 
        margin-right: rem(30px);
        border: 2px solid theme.colors.secondary;
        padding: 0px 5px;
        border-radius: 5px 15px 5px 5px;
    }


    > .duration {
        display: flex;
        padding-right: rem(30px);
        > svg {
            margin-right: rem(15px);
        }
    }

    > .competences {
        display: flex;
        > .competence {
            display: flex;
            margin-right: rem(25px);
            > svg {
                margin-right: rem(15px);
            }
            > a {
                color: theme.colors.gray-1;
            }

            &:hover {
                > a {
                    color: theme.colors.green;
                }           
            }

        }
    }

}


@media only screen and (max-width: theme.settings.breakpoints.small) {
    .unitpage-metacontainer {
        display:block;
        >.item {
            margin-bottom: rem(16px);
        }
    }
}