.mobilenavigation-menu {
    
    position: absolute;
    top: rem(71px);
    min-width: 30%;
    right: 0;
    background-color: theme.colors.white;
    box-shadow: 0px 5px 7px 0px rgba(38,38,43,0.10);
    max-width: rem(375px);
    padding: rem(45px) rem(16px);

    > a.-showall, a.-about, a.-license  {

        color: theme.colors.black;
        justify-content: flex-start;

        &:hover {
            color: theme.colors.primary;
        }
    }

    > a.-showall, > a.-about  {
        padding-bottom: rem(20px);
    }

    > a.-license {
        padding-bottom: rem(60px);
    }



}