.unitpage-header {

    padding-bottom: rem(30px);
    padding-top: rem(90px);



    > .unittype {

        >.slugcode {
            color: theme.colors.red;
            font-family: 'Glober-Extra-Bold';
            >.number {
                color: #000;
            }
        }

        .pagenumber {
            color: theme.colors.gray-1;
        }

        display: flex;
        align-items: center;
        padding-right: rem(15px);
        padding-top: rem(60px);

        > svg {
            width: rem(60px);
            height: rem(60px);
        }

    }

    > .title {

        padding: rem(30px) 0;
    
    }

    > .meta {

        padding: rem(22px) 0;
        margin-bottom: rem(30px);
        border-top: 1px solid theme.colors.gray-2;
        border-bottom: 1px solid theme.colors.gray-2;

    }

    > .description {

        padding-bottom: rem(30px);

    }

    @media only screen and (max-width: theme.settings.breakpoints.small) { 
        padding-top: rem(60px);
    }


}