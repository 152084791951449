@import "lib/stylus/themes/default/setup";

theme.components.margin-box = {
  default: rem( 20px ),
  xsmall: rem( 10px ),
  small: rem( 15px ),
  medium: rem( 30px ),
  // large: rem( 60px ),
  large: rem(80px),
  xlarge: rem(120px),
  auto: auto,
}

theme.settings = {
  breakpoints: {
    xsmall: rem( 520px ),
    small: rem( 768px ),
    medium: rem( 980px ),
    large: rem( 1200px )
  }, 
  text-line-height: rem( 24px ),
  text-font: 'Glober-Regular',
}

theme.components.grid-layout.sizes = {
		'1':100%,
		'1-2':50%,
		'1-3':100%/3,
		'2-3':100%/3*2,
		'1-4':100%/4,
		'3-4':100%/4*3,

    '1-7':100%/7,
}


//hack to remove the ugly background on mobile chrome onClick
// div,
// img,
// a {
//   -webkit-tap-highlight-color: rgba(0,0,0,0);
// }

//project
@import "lib/stylus/themes/default/components";
@import "./styleguide/**.styl";
@import "./components/**.styl";
@import "./components/**/**.var.styl";
@import "./components/**/**.styl";
@import "./helpers/**.styl";

svg path {
  fill: inherit;
}

html, body {
    height: 100%;
   position: relative;
}

