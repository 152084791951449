.unit-levelfilter {

    >.description {
        margin-bottom:rem(10px);

        a {
            color: theme.colors.primary;
        }
        a:hover {

            color: theme.colors.black;
        }
    }

    

     .filter {
        cursor: pointer;
        &:hover {
            color: theme.colors.primary;
        }
        &.-active {
             >.item {
                border-bottom: 2px solid  theme.colors.primary;
             }
             
        }
    }


    @media only screen and (max-width: theme.settings.breakpoints.small) {
        display: none;
    }
}