.collapse-ui {
    cursor: pointer;

    >.content {
        cursor: auto;
    }
    >.toggle {
        border-bottom: 2px solid theme.colors.black;
 

        > .collapse-icon {
            width: rem(24px);
            height: rem(24px);
            background-image: url('../images/icon-minus.svg');
            &:hover {
                background-image: url('../images/icon-minus-grey.svg');
            }
        }
    }

    &.-close {
        > .toggle {
            > .collapse-icon {
                background-image: url('../images/icon-plus.svg');
                &:hover {
                    background-image: url('../images/icon-plus-grey.svg');
                }
            }
        }
    }


}