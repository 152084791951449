.startpage-contents {

    .contents-row {
        .item {
            padding: 5px;
        }
        .pagenumber {
            width: rem(32px);
            font-weight: bold;
        }

    }
    

}
