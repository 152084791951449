
@page {
  margin: 1cm;

}

@media print {


    .pdf-license {
        page-break-after: always;
    }

}