.task-menu {

    >.add {
        cursor:pointer;
        &.-disabled{
            color:grey;
            cursor: auto;
        }

    }
    
    /*>.close {
        display:none;
        cursor:pointer;
    }
    >.share {
        display:none;
        cursor:pointer;
    }
    */
}