.hotspot-point {
    background-color:theme.colors.gray-1;
    border-radius: 50%;
    width: rem(25px);
    height: rem(25px);
    opacity: 0.6;

    &.-wrong {
        background-color:theme.colors.wrong;
    }
    
    &.-correct {
        background-color:theme.colors.primary;
    }

}