.button-general {

    height: rem(40px);
    border-radius: rem(20px);
    padding: rem(10px) rem(15px);
    color: theme.colors.white;
    text-transform: uppercase;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

}

