.magazine-preview {

    &.-hidden {
        display:none;
    }
    
    text-align:center;
    width: 100%;

    >.image-container {
        position: relative;
        display: inline-block;
        text-align:left;


        .preview-icon {
            color: theme.colors.green;
        }

        .future-publication {
            position: absolute;
            top: 0px;
            width: 100%;
            background-color:#009FE3;
            color:#fff;
            line-height: rem(48px);
            text-align:center
        }

    }



    &:hover {
        .preview {
            .preview-icon {
                display: none;
                &.-hover {
                    display: inline-block;
                }
            }
        }

    .image {
        box-shadow: 0px 2px 16px rgba(107, 107, 107, 0.50)
        transition: box-shadow 0.3s;
        }

    }

    .image {
        box-shadow: 0 2px 4px 0 rgba(107,107,107,0.30);
        max-height: rem(320px);
    }

    .date {
        padding-top: rem(15px);
    }


    .preview {
        text-align:center;
        position: absolute;
        top: 130px;
        transform: translate(-50%, 0%);
        left: 50%;
        .preview-icon {
            width: 60px;
            height: 60px;
            &.-hover {
                display:none;
            }
            
        }
        .text {
            color: theme.colors.green;
            font-size: rem(16px);
            font-weight: bold;
        }
    }



    &.-future{
        .image-container {
            .cart-component {
                bottom: 10px;
            }
        }
    }


    &.-disabled{
        .image-container {
            .image, .date, .title {
                // filter: grayscale(100%);
                opacity: 0.5;
            }
        }
    }


}