.navigation-mainheader {
    
    text-align: right;
    display: flex;
    //display: none;
    align-items: center;
    justify-content: flex-end;

    > .navigation-link {
        margin-right: rem(24px);
    }

    > a {
        color: theme.colors.black;
    }

    @media only screen and (max-width: theme.settings.breakpoints.medium) {

        display: none;

    }

}