.cart-component {
    
    
    .button {
        .svg-icon {
            width: 20px;
        }
    }
}

@media only screen and (max-width: theme.settings.breakpoints.small) {
    .cart-component {
        margin-bottom: 10px;
    }
}