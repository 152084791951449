.start-licenseinstruction {
    background-color: theme.colors.secondary;
    color: #fff;

    .p-big, h2 {
        color: #fff;
    }

    .svg-icon {
        
        &.cart {
            width: rem(70px);
            height: rem(65px);
        }
        &.url {
            // width: rem(173px);
            width: rem(138px)
            height: rem(65px);
        }
        &.code {
            // width: rem(157px);
            // width: rem(138px);
            width rem(70px);
            height: rem(65px);
        }
        &.book {
            width: rem(70px);
            height: rem(65px);
        }
    }

    .arrow {
        margin-top: rem(20px);
    }
    .elements {
        margin-top: rem(40px);
        .element {
            text-align:center;
            .text {
                color: #fff;
            }
            
        }
    }
    

    .container {
        padding: rem(60px) rem(16px);
    }


    @media only screen and (max-width: theme.settings.breakpoints.small) {

        .elements {
            margin-top: rem(30px);
            .element {
                text-align: center;
            }

            .arrow {
                transform: rotate(90deg);
                margin-bottom: rem(20px);
            }
            .url, .code {
                margin-bottom: rem(37px);
            }
        }

        .container {
            padding: rem(60px) rem(0px);
        }

    }

}