.license-content {
    margin-bottom: rem(120px);
    &.-auth {
        .auth {
            .dialog {
                background-color: theme.colors.gray-3;
                padding-top: rem(150px);
                padding-bottom: rem(60px);
                margin-bottom: rem(60px);
            }
        }
    }
   
    &.-noauth {
        .auth {
            position: relative;
            .overlay {
                position: absolute;
                z-index: 50;
                width: 100%;
                height: 100%;
                opacity: 0.7;
                background-color: #fff;
            }
        }
        .noauth {
            background-color: theme.colors.background-green
            padding-top: rem(150px);
            padding-bottom: rem(60px);
            margin-bottom: rem(60px);
        }
    }
    .button {
        display: inline-block;
    }
    
    .cart-component {
        >.button {
                width: 200px;
                display: flex;
            }
    }
    


    .link {
        border-bottom: 1px solid #009a5b;
        color: #009a5b;
        text-rendering: optimizeLegibility;
        &:hover {
            color: #6b6b6b;
        }
    }

    .user-licenses {
        >.table {

            width: 100%;
            th {
                text-align: left;
            }
            td, th {
                padding: rem(4px);
            }
        }
    }





}