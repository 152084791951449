.form-component {

    .input {
        background-color: theme.colors.gray-3;
        color: #000;
        border: 2px solid darkgrey;
        padding: rem(10px);
        font-size: rem(20px)
        width: rem(200px);
        margin-bottom: rem(16px);
    }
    .button {
        width: rem(130px);
        line-height: 0.8;
        margin-bottom: rem(16px);
        
        &:focus {
            box-shadow: 0 0 3pt 2pt theme.colors.primary;
            outline: none;
            border:none;
        }
    }
    
}