.navigation-unitheader {
    
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > a {
        color: theme.colors.black;
    }

    > .close {

        padding-left: rem(30px);

        > svg {
            width: rem(40px);
            height: rem(40px);
        }
    }

    @media only screen and (max-width: theme.settings.breakpoints.small) {

        > .download {

            > .downloadtext {

                display: none;

            }

        }

        > .close { 

            padding-left: rem(20px);
            
        }
    }

}