.preview-switch {
     text-transform: uppercase;
    padding: rem(55px) 0
    background-color: theme.colors.background-blue;

     .toggle {
         width: 64px;
         height: 40px;
         margin: 0 16px;
     }
     .preview, .magazine {
         display: inline-block;
         color: theme.colors.gray-1;

         &.-active {
             color: theme.colors.primary;
         }
         &:hover {
             color: theme.colors.primary;
         }

     }

     .cart-component {
         display: inline-block;
         .button {
            width: 150px;
            float: right;
         }
         
     }



}


@media only screen and (max-width: theme.settings.breakpoints.small) { 
    .preview-switch.navigation-text {
        font-size: rem(14px);
    }
    .preview-switch {
        display: flex;
        justify-content: center;
        >.element-layout {
            width: unset;
        }
        .cart-component {
            display:none;
        }
    
    
    }

}

