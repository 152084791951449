.start-testimonialcontainer {
    margin-bottom: rem(60px);
    background-color: theme.colors.secondary;
    text-align: center;
    position: relative;

    height: rem(350px);

    .testimonial {
        opacity: 1;
        transition: opacity 1s;

        .quote, .autor {
            color: #fff;
        }
        .quote > p {
            padding-bottom: rem(10px);
            display:inline;
            color: #fff;
        }
        &.-hidden {
            display: none;
            opacity: 0;
        }
    }



    .start-pointnavigation {
        .point {
            border: 1px solid theme.colors.light-blue;
            background-color:theme.colors.light-blue;
            &.-active {
                background-color: #fff;
            }
        }
    }

    @media only screen and (max-width: theme.settings.breakpoints.small) { 
        margin-bottom: rem(50px);
        height: rem(400px);
        
        .testimonial {
            .quote > p {
                font-family: 'Glober-Bold';
                font-size: rem(26px);
                line-height: rem(34px);
            }
        }
    }
}