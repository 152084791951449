.start-magazineoverview {
    margin-bottom: rem(60px);
    position: relative;
    
    .start-pointnavigation {
        bottom: 100px;
        display: none;
    }


    @media only screen and (max-width: theme.settings.breakpoints.medium) { 
        margin-bottom: rem(40px);
        .description {
            display:none;
        }
        .start-pointnavigation {
            display: block;
        }

        .button-container {
            text-align: center;
        }
        .buttons {
            display: inline-block;
            >.order {
                margin: 0 auto rem(15px) auto;
            }
        }
    }
}