.magazine-header {

     
     >.cover-image {
          position:relative;
          //margin-top: 70px;

          height: 460px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;

          >.transparent {
               position:absolute;
               width:100%;
               height: 100%;
               background-color: rgba(0,0,0,0.3);
               z-index:5;
          }
          
          > .content {
               position:absolute;
               z-index: 10;
               width: 100%;
               padding-top: 14rem;
               > .date {
                    color: theme.colors.white;
               }
          }

          &.-mobile {
               display: none;
          }
     }

    @media only screen and (max-width: theme.settings.breakpoints.small) {
        >.cover-image {
               display: none;
               
               &.-mobile {
                    display: block;
                    height: 300px;
                    > .content {
                         padding-top: 8rem;
                    }
               }
        }
    }

}


