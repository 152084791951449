.button-primary {

    background-color: theme.colors.primary;
    border: none;
    &:hover {

        background-color: theme.colors.primary-hover;

    }

    &.-disabled {

        cursor: default;
        background-color: theme.colors.gray-2;
        color: theme.colors.black;
        
    }

}

