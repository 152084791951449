.mobilenavigation-mainheader {
    
    display: none;
    text-align: right;
    align-items: center;
    justify-content: flex-end;

    > a {
        color: theme.colors.black;
    }

    @media only screen and (max-width: theme.settings.breakpoints.medium) {

        display: flex;
        //display: none;

    }

}