.task-alert {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 200;
    background-color: rgba(0,0,0,0.7);

    .content {
        background-color: #fff;
        width: 50%;
        padding: rem(64px);
        position: absolute;
        left: 25vw;
        top: 100px;
        
        .button, .message {
            text-align: center;
        }

    }

    @media only screen and (max-width: theme.settings.breakpoints.xsmall) {
        .content {
            top: 50px;
            width:90%;
            left: 5vw;
            padding: rem(32px);
        }
    }
}