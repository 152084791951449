.answer-container {

    padding-bottom: rem(25px);
    
    > .input {
        margin-right: rem(15px);
        //hack to avoid shrinking of checkboxes
        flex:none;
    }

}