.button-secondary {

    background-color: theme.colors.white;
    border: 2px solid theme.colors.primary;
    color: theme.colors.primary;

    &:hover {
        background-color: theme.colors.primary;
        color: theme.colors.white;
    }

    &.-active {
        background-color: theme.colors.primary;
        color: theme.colors.white;
    }

}