.ie-fixMinHeight {
    display:flex;
    .page {
        min-height:100vh;
        position:relative;
        width:100%;
        display:flex;
        flex-direction:column;

        .content {
          flex-grow: 1;
          //hack for lizenz-page
          // background-color: theme.colors.gray-1;
          // &.-noauth {
          //   >.auth {
          //     opacity: 0.5;
          //   }
            
          // }
        }
    }

}




//doesnt work for ie11
/*
html, body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body >.content {
    flex: 1;
}

*/