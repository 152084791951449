.teacher-comment {

    display: flex;
    padding-top: rem(30px);

    > .download {
        display: flex;
        > svg {
            margin-right: rem(16px);
        }
    }

}