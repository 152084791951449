/*
---
name: Colors
category: Shared/Variables
template: colors
tag:
 - variables
 - colors
colors:
 - variable: "text-color"
   description: "The default text color on the body"
   value: "#3F3F40"
 - variable: "text-inverted-color"
   description: "The default inverted text color on the body"
   value: "#FFF"
 - variable: "action-color"
   description: "The main action color used for links and buttons"
   value: "#EC6449"
 - variable: "body-color"
   description: "The main background color"
   value: "#FFF"
 - variable: "enabled-color"
   description: "Signalcolor for enabled/ok/success/true - search for a better name"
   value: "#7CAE7A"
 - variable: "disabled-color"
   description: "Signalcolor for disabled/notok/error/false - search for a better name"
   value: "#EC6449"
 - variable: "outline-navigation-color"
   description: "Color for the elements in the outline navigation"
   value: "lighten( #3F3F40, 60 )"
---

The color variables that are used throughout the library. 
*/
/*
---
name: Global settings
category: Shared/Variables
template: variables
tag:
 - variables
variables:
 - name: "text-line-height"
   description: "The line height of the body"
   default: "1.5rem"
 - name: "font-family"
   description: "The font-family of the body"
   default: "'Helvetica', sans-serif"
 - name: "text-rendering"
   description: "The text-rendering of the body"
   default: "optimizeLegibility"
 - name: "transition-duration"
   description: "The duration of the slide transitions"
   default: "0.4s"
 - name: "border-radius"
   description: "The default radius for borders"
   default: "2px"
---

These variables setups some global text formatting and styling.
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: inherit;
  vertical-align: baseline;
}
:root {
  font-size: 16px;
}
html {
  font-size: 100%;
}
body {
  line-height: 1.5rem;
  color: #202023;
  font-family: 'Glober-Regular';
  text-rendering: ;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
select {
  vertical-align: middle;
}
textarea {
  width: 100%;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
a {
  text-decoration: none;
  color: #3031fe;
}
img {
  display: block;
}
ul,
ol {
  margin-left: 1.25rem;
}
/*
---
name: .border-box
category: Box/Border
tag:
 - component
 - border
compile: true
---

A box to control borders on all four sides.

```jade
div.border-box.-left Some demo content
```

```jade
div.border-box.-all Some demo content
```
*/
.border-box.-top {
  border-top-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-right {
  border-right-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-bottom {
  border-bottom-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-left {
  border-left-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-all {
  border-style: solid;
  border-width: 1px;
  border-color: #e0e0e0;
}
.border-box.-round {
  border-radius: 50%;
}
/*
---
name: .color-box
category: Box/Color
tag:
 - component
 - border
compile: true
---

A box to control the background color. It makes a variant foreach color definion in the file `color-box.var.styl`

```jade
div.color-box.-white(style="width:100px;height:100px")
```

```jade
div.color-box.-action(style="width:100px;height:100px")
```
*/
.color-box.-body {
  background-color: #fff;
}
.color-box.-action {
  background-color: #3031fe;
}
.color-box.-white {
  background-color: #fff;
}
.color-box.-black {
  background-color: #000;
}
/*
---
name: .margin-box
category: Box/Margin
tag:
 - component
 - layout
compile: true
---

A component to add margins

## Applying margins

The easiest use case is to use simple `.margin-box .-all` for a margin on top, right, bottom and left with default size or
`.-top`, `.-right`, `.-bottom` and `.-left` for specified sides.

```jade
div.margin-box.-all
	div.aigis-debug Default margin applied on top, right, bottom and left
```
```jade
div.margin-box.-bottom
	div.aigis-debug Default margin applied on bottom
```


## Variants

The variants for the margins are `.-top-{size}`, `.-right-{size}`, `.-bottom-{size}` and `.left-{size}` together with sizes small, medium or large.
All sizes are defined in the variable theme.components.margin-box. Values are `small`, `medium`, `large` and `auto`

```jade
div.margin-box.-left-small
    div.aigis-debug Small margin applied on left
```
```jade
div.margin-box.-right-small
    div.aigis-debug Small margin applied on right
```
```jade
div.margin-box.-top-small
    div.aigis-debug Small margin applied on top
```
```jade
div.margin-box.-bottom-small
    div.aigis-debug Small margin applied on bottom
```

There are also shortcuts for vertical `.-v-{size}` and horizontal `.-h-{size}` together with sizes small, medium and large

```jade
div.margin-box.-v-medium
    div.aigis-debug Medium margin applied on top and bottom
```
```jade
div.margin-box.-h-medium
    div.aigis-debug Medium margin applied on right and left
```

## Variants for auto margins

```jade
div.margin-box.-auto.-h(style="width: 200px")
    div.aigis-debug Auto margin applied on right and left
```
```jade
div.margin-box.-auto.-v(style="height: 46px")
    div.aigis-debug Auto margin applied on top and bottom
```

*/
.margin-box.-top {
  margin-top: 1.25rem;
}
.margin-box.-right {
  margin-right: 1.25rem;
}
.margin-box.-bottom {
  margin-bottom: 1.25rem;
}
.margin-box.-left {
  margin-left: 1.25rem;
}
.margin-box.-h {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}
.margin-box.-v {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.margin-box.-all {
  margin: 1.25rem 1.25rem;
}
.margin-box.-top-xsmall {
  margin-top: 0.625rem;
}
.margin-box.-right-xsmall {
  margin-right: 0.625rem;
}
.margin-box.-bottom-xsmall {
  margin-bottom: 0.625rem;
}
.margin-box.-left-xsmall {
  margin-left: 0.625rem;
}
.margin-box.-h-xsmall {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}
.margin-box.-v-xsmall {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.margin-box.-all-xsmall {
  margin: 0.625rem 0.625rem;
}
.margin-box.-top-small {
  margin-top: 0.9375rem;
}
.margin-box.-right-small {
  margin-right: 0.9375rem;
}
.margin-box.-bottom-small {
  margin-bottom: 0.9375rem;
}
.margin-box.-left-small {
  margin-left: 0.9375rem;
}
.margin-box.-h-small {
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
}
.margin-box.-v-small {
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
}
.margin-box.-all-small {
  margin: 0.9375rem 0.9375rem;
}
.margin-box.-top-medium {
  margin-top: 1.875rem;
}
.margin-box.-right-medium {
  margin-right: 1.875rem;
}
.margin-box.-bottom-medium {
  margin-bottom: 1.875rem;
}
.margin-box.-left-medium {
  margin-left: 1.875rem;
}
.margin-box.-h-medium {
  margin-left: 1.875rem;
  margin-right: 1.875rem;
}
.margin-box.-v-medium {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
}
.margin-box.-all-medium {
  margin: 1.875rem 1.875rem;
}
.margin-box.-top-large {
  margin-top: 5rem;
}
.margin-box.-right-large {
  margin-right: 5rem;
}
.margin-box.-bottom-large {
  margin-bottom: 5rem;
}
.margin-box.-left-large {
  margin-left: 5rem;
}
.margin-box.-h-large {
  margin-left: 5rem;
  margin-right: 5rem;
}
.margin-box.-v-large {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.margin-box.-all-large {
  margin: 5rem 5rem;
}
.margin-box.-top-xlarge {
  margin-top: 7.5rem;
}
.margin-box.-right-xlarge {
  margin-right: 7.5rem;
}
.margin-box.-bottom-xlarge {
  margin-bottom: 7.5rem;
}
.margin-box.-left-xlarge {
  margin-left: 7.5rem;
}
.margin-box.-h-xlarge {
  margin-left: 7.5rem;
  margin-right: 7.5rem;
}
.margin-box.-v-xlarge {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}
.margin-box.-all-xlarge {
  margin: 7.5rem 7.5rem;
}
.margin-box.-top-auto {
  margin-top: auto;
}
.margin-box.-right-auto {
  margin-right: auto;
}
.margin-box.-bottom-auto {
  margin-bottom: auto;
}
.margin-box.-left-auto {
  margin-left: auto;
}
.margin-box.-h-auto {
  margin-left: auto;
  margin-right: auto;
}
.margin-box.-v-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.margin-box.-all-auto {
  margin: auto auto;
}
.margin-box.-auto.-v {
  margin-top: auto;
  margin-bottom: auto;
}
.margin-box.-auto.-h {
  margin-left: auto;
  margin-right: auto;
}
/*
---
name: .padding-box
category: Box/Padding
tag:
 - component
 - layout
compile: true
---

A simple component to add paddings

## Applying paddings

The easiest use case is to use simple `.padding-box .-all` for a padding on top, right, bottom and left with default size or
`.-top`, `.-right`, `.-bottom` and `.-left` for specified sides

```jade
div.aigis-debug.padding-box.-all Defaut padding applied on top, right, bottom and left
```
```jade
div.aigis-debug.padding-box.-bottom Defaut padding applied on bottom
```

## Variants

The variants for the paddings are `.-top-{size}`, `.-right-{size}`, `.-bottom-{size}` and `.left-{size}` together with sizes small, medium or large.
All sizes are defined in the variable theme.components.padding-box. Values are `small`, `medium`, and `large`

```jade
div.aigis-debug.padding-box.-left-small Small padding applied on left
```
```jade
div.aigis-debug.padding-box.-right-small(style="text-align:right") Small padding applied on right
```
```jade
div.aigis-debug.padding-box.-top-small Small padding applied on top
```
```jade
div.aigis-debug.padding-box.-bottom-small Small padding applied on bottom
```

There are also shortcuts for vertical `.-v-{size}` and horizontal `.-h-{size}` together with sizes

```jade
div.aigis-debug.padding-box.-v-medium Medium padding applied on top and bottom
```
```jade
div.aigis-debug.padding-box.-h-medium(style="width: 300px") Medium padding applied on right and left
```
```jade
div.aigis-debug.padding-box.-h-medium.-top-large(style="width: 400px") Medium padding applied on right and left, large padding applied on top
```
*/
.padding-box.-top {
  padding-top: 0.5rem;
}
.padding-box.-right {
  padding-right: 0.5rem;
}
.padding-box.-bottom {
  padding-bottom: 0.5rem;
}
.padding-box.-left {
  padding-left: 0.5rem;
}
.padding-box.-h {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.padding-box.-v {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.padding-box.-all {
  padding: 0.5rem 0.5rem;
}
.padding-box.-top-small {
  padding-top: 0.125rem;
}
.padding-box.-right-small {
  padding-right: 0.125rem;
}
.padding-box.-bottom-small {
  padding-bottom: 0.125rem;
}
.padding-box.-left-small {
  padding-left: 0.125rem;
}
.padding-box.-h-small {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}
.padding-box.-v-small {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.padding-box.-all-small {
  padding: 0.125rem 0.125rem;
}
.padding-box.-top-medium {
  padding-top: 1rem;
}
.padding-box.-right-medium {
  padding-right: 1rem;
}
.padding-box.-bottom-medium {
  padding-bottom: 1rem;
}
.padding-box.-left-medium {
  padding-left: 1rem;
}
.padding-box.-h-medium {
  padding-left: 1rem;
  padding-right: 1rem;
}
.padding-box.-v-medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.padding-box.-all-medium {
  padding: 1rem 1rem;
}
.padding-box.-top-large {
  padding-top: 2rem;
}
.padding-box.-right-large {
  padding-right: 2rem;
}
.padding-box.-bottom-large {
  padding-bottom: 2rem;
}
.padding-box.-left-large {
  padding-left: 2rem;
}
.padding-box.-h-large {
  padding-left: 2rem;
  padding-right: 2rem;
}
.padding-box.-v-large {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.padding-box.-all-large {
  padding: 2rem 2rem;
}
.padding-box.-gutter {
  padding: 0.9375rem 0.9375rem;
}
/*
---
name: .shadow-box
category: Box/Shadow
tag:
 - component
 - shadow
 - helper
compile: true
---

A box to control box-shadows with different variants

## Default variants
```jade
div.shadow-box Demo content
div.shadow-box.-inset Demo content
div.shadow-box.-zero Use zero shadow in animations
```
*/
.shadow-box {
  box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.17);
}
.shadow-box.-inset {
  box-shadow: inset 0px 1px 6px 0px rgba(0,0,0,0.17);
}
.shadow-box.-zero {
  box-shadow: inset 0px 0px 0px 0px rgba(0,0,0,0.17);
}
/*
---
name: .transform-box
category: Box/Transform
tag:
 - component
 - tranform
 - helper
compile: true
---

A box to control common transformations

## Rotate
```jade
div.transform-box.-rot90 Some demo content
div.transform-box.-rot180 Some demo content
div.transform-box.-rot270 Some demo content
```

## Flip
```jade
div.transform-box.-flipX Some demo content
div.transform-box.-flipY Some demo content
```
*/
.transform-box.-rot90 {
  transform: rotate(90deg);
}
.transform-box.-rot180 {
  transform: rotate(180deg);
}
.transform-box.-rot270 {
  transform: rotate(270deg);
}
.transform-box.-flipX {
  transform: scaleX(-1);
}
.transform-box.-flipY {
  transform: scaleY(-1);
}
/*
---
name: .button-style
category: Components/Button
tag:
 - component
 - button
compile: true
---

A button component as a base for different button variants defined in its configuration.

```jade
button.button-style.-main Action
```
*/
.button-style {
  appearance: none;
  background: transparent;
  border: none;
  display: inline-block;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.button-style.-main {
  color: #fff;
  background-color: #3031fe;
  padding: 0.125rem 0.5rem;
}
.button-style.-main.-hover:hover {
  color: #fff;
  background-color: #1616bd;
}
.button-style.-hover {
  transition: all 0.1s;
}
.button-style.-hover:hover {
  color: #3031fe;
}
.button-style.-disabled {
  background-color: #b6b6b6;
}
/*
---
name: .drop-down-menu
category: DropDown
tag:
 - component
 - dropdown
compile: true
---

This styles a menu inside a .drop-down.

```jade
div.drop-down-menu
	div.item Menu 1
	div.item Menu 2
	div.item Menu 3
```

Used in combination with the .drop-down itself
```jade
div.drop-down.-open
	div.toggle.aigis-debug Toggle
	div.content.drop-down-menu
		div.item Menu 1
		div.item Menu 2
		div.item Menu 3
```

*/
.drop-down-menu {
  box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.17);
  border: 1px solid #e0e0e0;
  border-top: none;
}
.drop-down-menu > .item + .item {
  border-top: 1px solid #e0e0e0;
}
/*
---
name: .drop-down
category: DropDown
tag:
 - component
 - dropdown
compile: true
---

This dropdown has the simple mechanism to open and close a dropdown content.

Trigger with mouseover by adding `.-hover` variant

Trigger with js by adding `.-open` variant 

Closed Dropdown
```jade
div.drop-down
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```
## Variants

Variants `.-top`, `.-bottom`, `.-left` and `.-right` can be combined

Bottom left
```jade
div.drop-down.-hover.-bottom.-left
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```

Bottom right
```jade
div.drop-down.-hover.-bottom.-right
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```

Top right
```jade
div.drop-down.-hover.-top.-right
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```

Top left
```jade
div.drop-down.-hover.-top.-left
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```
## Aside

There is an `.-aside` variant to open content aside

Top left aside
```jade
div.drop-down.-hover.-top.-left.-aside(style="left:200px;width:50%")
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```

Top right aside
```jade
div.drop-down.-hover.-top.-right.-aside(style="width:50%")
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```
## Relative content

There is a variant for opening the content relative instead of absolute

Relative
```jade
div.drop-down.-hover.-relative(style="width:50%")
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```

JS Trigger
```jade
div.drop-down.-open.-bottom.-left
	div.toggle.aigis-debug Toggle
	div.content Dropdown content
```

## Do not wrap content

A variant preventing the content from wrapping


```jade
div.drop-down.-hover.-nowrap
	div.toggle.aigis-debug Toggle
	div.content Dropdown content with some whitespaces
```

*/
.drop-down {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.drop-down.-open > .content {
  display: block;
}
.drop-down.-hover:hover > .content {
  display: block;
}
.drop-down.-top > .content {
  bottom: 100%;
}
.drop-down.-bottom > .content {
  top: 100%;
}
.drop-down.-left > .content {
  left: 0;
}
.drop-down.-top.-left.-aside > .content {
  transform: translate(-100%, 100%);
}
.drop-down.-right > .content {
  right: 0;
}
.drop-down.-top.-right.-aside > .content {
  transform: translate(100%, 100%);
}
.drop-down.-relative > .content {
  position: relative;
}
.drop-down.-nowrap > .content {
  white-space: nowrap;
}
.drop-down > .toggle {
  display: inline-block;
}
.drop-down > .content {
  position: absolute;
  z-index: 1000;
  display: none;
}
.svg-icon {
  fill: currentColor;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
}
.svg-icon.-inline {
  display: inline-block;
}
.svg-icon.-scale {
  width: 100%;
  height: 100%;
}
/*
---
name: .abs-layout
category: Layout/Absolute
tag:
 - component
 - layout
compile: true
---

A simple component to position an element absolute. Default is top, left

## Variants

The variants for the vertical positioning are `.-top`, `.-vcenter` and `.-bottom`.
The variants for the horizontal positioning are `.-right`, `.-hcenter` and `.-left`.
Additinally the `-center` variant will center horizontal and vertical.
Variants to stretch an item to its full width/height can be dine with `.item.-stretch`.
In some situations the abs-layout itself needs to stretch so use the stretch variant on the component. `.abs-layout.-stretch` 

```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-center
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-top.-hcenter
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-top.-right
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-right.-vcenter
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-right.-bottom
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-bottom.-hcenter
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-bottom.-left
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-left.-vcenter
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-left.-top
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-stretch
```

## Z-Index

Every item can controll its z index with the `.-z{i}`variants.
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-top.-left.-z1
    div.aigis-debug.item.-top.-left.-z2
```

*/
.abs-layout {
  position: relative;
}
.abs-layout.-stretch {
  width: 100%;
  height: 100%;
}
.abs-layout > .item {
  position: absolute;
}
.abs-layout > .item.-top {
  top: 0;
}
.abs-layout > .item.-right {
  right: 0;
}
.abs-layout > .item.-bottom {
  bottom: 0;
}
.abs-layout > .item.-left {
  left: 0;
}
.abs-layout > .item.-vcenter {
  top: 50%;
  transform: translateY(-50%);
}
.abs-layout > .item.-hcenter {
  left: 50%;
  transform: translateX(-50%);
}
.abs-layout > .item.-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.abs-layout > .item.-stretch {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.abs-layout > .item.-z1 {
  z-index: 1;
}
.abs-layout > .item.-z2 {
  z-index: 2;
}
.abs-layout > .item.-z3 {
  z-index: 3;
}
.abs-layout > .item.-z4 {
  z-index: 4;
}
.abs-layout > .item.-z5 {
  z-index: 5;
}
.abs-layout > .item.-z6 {
  z-index: 6;
}
.abs-layout > .item.-z7 {
  z-index: 7;
}
.abs-layout > .item.-z8 {
  z-index: 8;
}
.abs-layout > .item.-z9 {
  z-index: 9;
}
.abs-layout > .item.-z10 {
  z-index: 10;
}
/*
---
name: .fixed-layout
category: Layout/Fixed
tag:
 - container
---

Fixed container
You can position the container with the different variants `.-top`, `.-bottom`, `.-left`, `.-right`.
To span full width / height use `.-left` & `.-right` / `.-top` & `.-bottom`

Fixed top and full width

```jade
div.fixed-layout.-top.-left.-right.aigis-debug top left fullwidth
    
```

Fixed right and full height

```jade
div.fixed-layout.-top.-bottom.-right.aigis-debug top right fullheight
    
```

*/
.fixed-layout {
  position: fixed;
}
.fixed-layout.-top {
  top: 0;
}
.fixed-layout.-bottom {
  bottom: 0;
}
.fixed-layout.-left {
  left: 0;
}
.fixed-layout.-right {
  right: 0;
}
/*
---
name: .fixed-page-layout
category: Layout/Fixed Page
tag:
 - component
 - page
compile: true
---

A page layout with a fixed header and optional sidebars.
With the `-margin-{top|left|right}`, `-margin-{top|left|right}-small` variants you can control the spacing.
If the content for example needs a spacing towards the header use: `.content.-margin-top`.
If the sidebar show be below the header use: `.sidebar.-left.-margin-top`.


There are variants to resize the header and sidebars to a smaller width/height: `.header.-small`, `.sidebar.-small`.
If you need a spacing on your content or sidebar you need to adjust that manually like this:
```
div.fixed-page-layout
	div.header.-small
		div.aigis-debug(style="width:100%;height:100%")  Header
	div.content.-margin-top-small Content
	div.sidebar.-left.-margin-top-small.-small
		div.aigis-debug(style="height:100%")  Left Sidebar
```

## Header with sidebars

```jade
div.fixed-page-layout
	div.header
		div.aigis-debug(style="width:100%;height:100%")  Header
	div.content.-margin-top Content
	div.sidebar.-left.-margin-top
		div.aigis-debug(style="height:100%")  Left Sidebar
	div.sidebar.-right
		div.aigis-debug(style="height:100%")  Right Sidebar
```
*/
.fixed-page-layout > .header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3.125rem;
  transition: all 0.2s;
  z-index: 1000;
}
.fixed-page-layout > .header.-small {
  height: 1.875rem;
}
.fixed-page-layout > .header.-hide {
  transform: translateY(-100%);
}
.fixed-page-layout > .sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 14.5625rem;
  transition: all 0.2s;
  z-index: 1005;
}
.fixed-page-layout > .sidebar.-margin-top {
  top: 3.125rem;
}
.fixed-page-layout > .sidebar.-margin-top-small {
  top: 1.875rem;
}
.fixed-page-layout > .sidebar.-small {
  width: 3.125rem;
}
.fixed-page-layout > .sidebar.-left {
  left: 0;
}
.fixed-page-layout > .sidebar.-left.-hide {
  transform: translateX(-100%);
}
.fixed-page-layout > .sidebar.-right {
  right: 0;
}
.fixed-page-layout > .sidebar.-right.-hide {
  transform: translateX(100%);
}
.fixed-page-layout > .content {
  transition: margin 0.2s;
}
.fixed-page-layout > .content.-margin-left {
  margin-left: 14.5625rem;
}
.fixed-page-layout > .content.-margin-left-small {
  margin-left: 3.125rem;
}
.fixed-page-layout > .content.-margin-right {
  margin-right: 14.5625rem;
}
.fixed-page-layout > .content.-margin-right-small {
  margin-right: 3.125rem;
}
.fixed-page-layout > .content.-margin-top {
  margin-top: 3.125rem;
}
.fixed-page-layout > .content.-margin-top-small {
  margin-top: 1.875rem;
}
/*
---
name: .flex-layout
category: Layout/Flex
tag:
 - component
 - layout
 - flexbox
compile: true
---

A layout system based on flexbox to align and distribute items horizontally and vertically.


# Alignment of items

The `-center` shortcut will center all items horizontal & vertical
```jade
div(style="height: 100px").flex-layout.-center
	div.aigis-debug Item 1
	div.aigis-debug Item 2
	div.aigis-debug Item 3
```

`-baseline` will align items based on their baseline. Works good if all the items contain text. 
```jade
div.flex-layout.-baseline
	div.aigis-debug Item
	div.aigis-debug(style="padding-top: 50px;padding-bottom: 30px;") Item 2
```

`-items-top` & `-items-bottom` will align the items either on top or bottom
```jade
div.flex-layout.-items-top
	div.aigis-debug Item 1
	div.aigis-debug(style="padding-top: 50px;padding-bottom: 30px;") Item 2
	div.aigis-debug Item 3
```
```jade
div.flex-layout.-items-bottom
	div.aigis-debug Item 1
	div.aigis-debug(style="padding-top: 50px;padding-bottom: 30px;") Item 2
	div.aigis-debug Item 3
```

`-items-center` will center the items vertically
```jade
div.flex-layout.-items-center
	div.aigis-debug Item 1
	div.aigis-debug(style="padding-top: 50px;padding-bottom: 30px;") Item 2
	div.aigis-debug Item 3
```

# Alignment of individual items

```jade
div(style="height: 100px").flex-layout
	div.aigis-debug.item.-top top
	div.aigis-debug.item.-center center
	div.aigis-debug.item.-bottom bottom
	div.aigis-debug.item.-stretch stretch
```

# Row mode

If you like a container that spans the full width with item that resize themself you can use the `-row` variant.

```jade
div.flex-layout.-row
	div.aigis-debug.item Item 1
	div.aigis-debug.item Item 2
	div.aigis-debug.item Item 3
```

If you like to fix an item based on its content width use the `-content` variant.
This is a shortcut for `-no-grow -no-shrink`. 
```jade
div.flex-layout.-row
	div.aigis-debug.item.-content
		img(src="http://via.placeholder.com/350x80")
	div.aigis-debug.item Item 2
	div.aigis-debug.item Item 3
```

You can control the sizing of the items with `-grow`, `-shrink`, `-no-grow`, `-no-shrink`.
```jade
div.flex-layout.-row
	div.aigis-debug.item.-no-shrink
		img(src="http://via.placeholder.com/350x80")
	div.aigis-debug.item.-no-grow Item 2
	div.aigis-debug.item Item 3
```


# Distribution of items

Use `-[v/h]-space-around` & `-[v/h]-space-between` to distribute the items in the flex container.

```jade
div.flex-layout.-h-space-around
	div.aigis-debug Item 1
	div.aigis-debug Item 2
	div.aigis-debug Item 3
```
```jade
div.flex-layout.-h-space-between
	div.aigis-debug Item 1
	div.aigis-debug Item 2
	div.aigis-debug Item 3
```
```jade
div(style="height: 200px").flex-layout.-v-space-around.-wrap
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div(style="height: 200px").flex-layout.-v-space-between.-wrap
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```

# Alignment of multiline items with wrap

If you have alot on items that wrap you can align the multilines with the following variants:
```jade
div.flex-layout.-wrap.-h-center
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div.flex-layout.-wrap.-left
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div.flex-layout.-wrap.-right
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div(style="height: 200px").flex-layout.-wrap.-v-center
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div(style="height: 200px").flex-layout.-wrap.-top
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```
```jade
div(style="height: 200px").flex-layout.-wrap.-bottom
	div.aigis-debug Item longer 1
	div.aigis-debug Item longer 2
	div.aigis-debug Item longer 3
	div.aigis-debug Item longer 4
	div.aigis-debug Item longer 5
	div.aigis-debug Item longer 6
	div.aigis-debug Item longer 7
	div.aigis-debug Item longer 8
	div.aigis-debug Item longer 9
```




*/
.flex-layout {
  display: flex;
}
.flex-layout.-inline {
  display: flex-inline;
}
.flex-layout.-wrap {
  flex-wrap: wrap;
}
.flex-layout.-row {
  flex-wrap: nowrap;
}
.flex-layout.-row > .item {
  flex: 1 1 auto;
}
.flex-layout.-column {
  flex-direction: column;
}
.flex-layout.-center {
  justify-content: center;
  align-items: center;
  align-content: center;
}
.flex-layout.-baseline {
  align-items: baseline;
}
.flex-layout.-left {
  justify-content: flex-start;
}
.flex-layout.-right {
  justify-content: flex-end;
}
.flex-layout.-top {
  align-content: flex-start;
}
.flex-layout.-bottom {
  align-content: flex-end;
}
.flex-layout.-v-center {
  align-content: center;
}
.flex-layout.-v-space-between {
  align-content: space-between;
}
.flex-layout.-v-space-around {
  align-content: space-around;
}
.flex-layout.-h-center {
  justify-content: center;
}
.flex-layout.-h-space-between {
  justify-content: space-between;
}
.flex-layout.-h-space-around {
  justify-content: space-around;
}
.flex-layout.-items-top {
  align-items: flex-start;
}
.flex-layout.-items-bottom {
  align-items: flex-end;
}
.flex-layout.-items-center {
  align-items: center;
}
.flex-layout.-items-stretch {
  align-items: flex-stretch;
}
.flex-layout > .item.-stretch {
  align-self: stretch;
}
.flex-layout > .item.-top {
  align-self: flex-start;
}
.flex-layout > .item.-center {
  align-self: center;
}
.flex-layout > .item.-bottom {
  align-self: flex-end;
}
.flex-layout > .item.-content {
  flex: none;
}
.flex-layout > .item.-grow {
  flex-grow: 1;
}
.flex-layout > .item.-shrink {
  flex-shrink: 1;
}
.flex-layout > .item.-no-grow {
  flex-grow: 0;
}
.flex-layout > .item.-no-shrink {
  flex-shrink: 0;
}
/*
---
name: .grid-layout
category: Layout/Grid
tag:
 - component
 - layout
 - grid
 - responsive
 - flexbox
compile: true
---

A grid layout system based on flexbox. It provides sizing variants to size on different percentages.


## Percentage based sizing

The easiest use case is to use simple `.item -w1`, `.item -w1-2`, `.item -w1-3`, `.item -w1-4` for a percentage based side-by-side rendering.

```jade
div.grid-layout
	div.item.-w1
		div.aigis-debug item -w1
	div.item.-w1-2 
		div.aigis-debug item -w1-2
	div.item.-w1-2
		div.aigis-debug item -w1-2
	div.item.-w1-3
		div.aigis-debug item -w1-3
	div.item.-w2-3
		div.aigis-debug item -w2-3
	div.item.-w1-4
		div.aigis-debug item -w1-4
	div.item.-w1-2
		div.aigis-debug item -w1-2
	div.item.-w1-4
		div.aigis-debug item -w1-4
```

## Layouts on different devices

The breakpoints for different screen sizes are specified in the global settings `theme.settings.breakpoints` and can be overwritten in `theme.components.grid-layout.breakpoints`.
The default settings define breakpoints with labels: `.-small-X-X`, `.-medium-X-X`, `.-large-X-X`.

```jade
div.grid-layout
	div.item.-w1.-small-1-3.-medium-1-2.-large-1-4
		div.aigis-debug item -w1 -small-1-3 -medium-1-2 -large-1-4
	div.item.-w1.-small-1-3.-medium-1-2.-large-1-4
		div.aigis-debug item -w1 -small-1-3 -medium-1-2 -large-1-4
	div.item.-w1-2.-small-1-3.-medium-1-2.-large-1-4
		div.aigis-debug item -w1-2 -small-1-3 -medium-1-2 -large-1-4
	div.item.-w1-2.-small-1.-medium-1-2.-large-1-4
		div.aigis-debug item -w1-2 -small-1 -medium-1-2 -large-1-4
```


## Gutter

If you need a spacing between the columns add the `-gutter` variant.
The size of the gutter is specified in the `grid-gutter`variable.

```jade
div.grid-layout.-gutter
	div.item.-w1-4
		div.aigis-debug col -w1-4
	div.item.-w1-4
		div.aigis-debug col -w1-4
	div.item.-w1-2
		div.aigis-debug col -w1-2
```


## Reverse order

To reverse the order in which the columns are displayed, you can add the modifier class `.-reverse` to your table-grid.
The columns will be in DOM order when viewport switches to small layout.

```jade
div.grid-layout.-reverse
	div.item.-w1-4
		div.aigis-debug First column in DOM
	div.item.-w1-4
		div.aigis-debug Second column in DOM
	div.item.-w1-4
		div.aigis-debug Third column in DOM
	div.item.-w1-4
		div.aigis-debug Fourth column in DOM
```

## Vertical centering

You can vertical center with the help of the `flex-layout` component.

```jade
div.grid-layout.flex-layout
	div.item.-w1-4
		div.aigis-debug A column with a lot of content that should wrap on fixed size.
	div.item.-bottom
		div.aigis-debug bottom
	div.item.-center
		div.aigis-debug center
	div.item.-stretch
		div.aigis-debug(style="height:100%") stretch
```

*/
.grid-layout {
  display: flex;
  flex-wrap: wrap;
}
.grid-layout.-gutter {
  margin: -0.625rem -0.9375rem;
}
.grid-layout.-gutter > .item {
  padding: 0.625rem 0.9375rem;
}
.grid-layout.-reverse {
  flex-direction: row-reverse;
}
.grid-layout > .item.-w1 {
  flex: 0 0 100%;
  max-width: 100%;
}
.grid-layout > .item.-w1-2 {
  flex: 0 0 50%;
  max-width: 50%;
}
.grid-layout > .item.-w1-3 {
  flex: 0 0 33.333333333333336%;
  max-width: 33.333333333333336%;
}
.grid-layout > .item.-w2-3 {
  flex: 0 0 66.66666666666667%;
  max-width: 66.66666666666667%;
}
.grid-layout > .item.-w1-4 {
  flex: 0 0 25%;
  max-width: 25%;
}
.grid-layout > .item.-w3-4 {
  flex: 0 0 75%;
  max-width: 75%;
}
.grid-layout > .item.-w1-7 {
  flex: 0 0 14.285714285714286%;
  max-width: 14.285714285714286%;
}
@media all and (min-width: 32.5rem) {
  .grid-layout > .item.-xsmall-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid-layout > .item.-xsmall-1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-layout > .item.-xsmall-1-3 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .grid-layout > .item.-xsmall-2-3 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .grid-layout > .item.-xsmall-1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-layout > .item.-xsmall-3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .grid-layout > .item.-xsmall-1-7 {
    flex: 0 0 14.285714285714286%;
    max-width: 14.285714285714286%;
  }
}
@media all and (min-width: 48rem) {
  .grid-layout > .item.-small-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid-layout > .item.-small-1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-layout > .item.-small-1-3 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .grid-layout > .item.-small-2-3 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .grid-layout > .item.-small-1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-layout > .item.-small-3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .grid-layout > .item.-small-1-7 {
    flex: 0 0 14.285714285714286%;
    max-width: 14.285714285714286%;
  }
}
@media all and (min-width: 61.25rem) {
  .grid-layout > .item.-medium-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid-layout > .item.-medium-1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-layout > .item.-medium-1-3 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .grid-layout > .item.-medium-2-3 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .grid-layout > .item.-medium-1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-layout > .item.-medium-3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .grid-layout > .item.-medium-1-7 {
    flex: 0 0 14.285714285714286%;
    max-width: 14.285714285714286%;
  }
}
@media all and (min-width: 75rem) {
  .grid-layout > .item.-large-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid-layout > .item.-large-1-2 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid-layout > .item.-large-1-3 {
    flex: 0 0 33.333333333333336%;
    max-width: 33.333333333333336%;
  }
  .grid-layout > .item.-large-2-3 {
    flex: 0 0 66.66666666666667%;
    max-width: 66.66666666666667%;
  }
  .grid-layout > .item.-large-1-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid-layout > .item.-large-3-4 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .grid-layout > .item.-large-1-7 {
    flex: 0 0 14.285714285714286%;
    max-width: 14.285714285714286%;
  }
}
/*
---
name: .max-width-layout
category: Layout/Page
tag:
 - component
 - layout
 - responsive
compile: true
---

This component will limit its content to a maximum width. Forthermore it can center its content.
Usefull for page content that should not span the full width.


## Percentage based sizing

*/
@media all and (min-width: 48rem) {
  .max-width-layout {
    max-width: 41.75rem;
  }
}
@media all and (min-width: 61.25rem) {
  .max-width-layout {
    max-width: 55.75rem;
  }
}
@media all and (min-width: 75rem) {
  .max-width-layout {
    max-width: 68.75rem;
  }
}
.max-width-layout.-center {
  margin-left: auto;
  margin-right: auto;
}
/*
---
name: .slide-overlay
category: Overlay/Slide
tag:
 - rollover
 - slide
 - overlay
---

Rollover component - content rollover rolls in - default from bottom

Trigger with mouserover by adding `.-hover` variant

Trigger with js by adding `.-show` variant

```jade
div.slide-overlay.-hover
    div Some main content
    div.rollover Rollover content
```

Rollover component variant -left
```jade
div.slide-overlay.-left.-hover
    div Some main content
    div.rollover Rollover content
```

Rollover component variant -right
```jade
div.slide-overlay.-right.-hover
    div Some main content
    div.rollover Rollover content
```

Rollover component variant -top
```jade
div.slide-overlay.-top.-hover
    div Some main content
    div.rollover Rollover content
```

Rollover component variant -top with js trigger
```jade
div.slide-overlay.-top.-show
    div Some main content
    div.rollover Rollover content
```
*/
.slide-overlay {
  position: relative;
  overflow: hidden;
}
.slide-overlay > .rollover {
  position: absolute;
  bottom: 0;
  transform: translate(0, 100%);
  transition: transform 0.5s ease;
}
.slide-overlay.-left > .rollover {
  transform: translate(-100%, 0);
  left: 0;
}
.slide-overlay.-right > .rollover {
  transform: translate(100%, 0);
  right: 0;
}
.slide-overlay.-top > .rollover {
  transform: translate(0, -100%);
  top: 0;
}
.slide-overlay.-show > .rollover {
  transform: translate(0, 0);
}
.slide-overlay.-hover:hover > .rollover {
  transform: translate(0, 0);
}
/*
---
name: .control-responsive
category: Responsive/Control
tag:
 - responsive
 - display
compile: true
---

Controls the display of an element depending on the current breakpoint

## Hide

`.-hide-medium` hides an element as long as the screen is wider than the breakpoint `medium`.

```jade
div.control-responsive.-hide-medium Only shown when the screen is wider than the breakpoint medium
```

## Show

`.-show-medium` show an element as long as the screen is narrower than the breakpoint `medium`.

```jade
div.control-responsive.-show-medium Only shown when the screen is narrower than the breakpoint `medium`
```

*/
@media all and (max-width: 32.5rem) {
  .control-responsive.-hide-xsmall {
    display: none;
  }
}
@media all and (min-width: 32.5rem) {
  .control-responsive.-show-xsmall {
    display: none;
  }
}
@media all and (max-width: 48rem) {
  .control-responsive.-hide-small {
    display: none;
  }
}
@media all and (min-width: 48rem) {
  .control-responsive.-show-small {
    display: none;
  }
}
@media all and (max-width: 61.25rem) {
  .control-responsive.-hide-medium {
    display: none;
  }
}
@media all and (min-width: 61.25rem) {
  .control-responsive.-show-medium {
    display: none;
  }
}
@media all and (max-width: 75rem) {
  .control-responsive.-hide-large {
    display: none;
  }
}
@media all and (min-width: 75rem) {
  .control-responsive.-show-large {
    display: none;
  }
}
@media all and (max-width: 32.5rem) {
  .hide-responsive.-xsmall {
    display: none;
  }
}
@media all and (max-width: 48rem) {
  .hide-responsive.-small {
    display: none;
  }
}
@media all and (max-width: 61.25rem) {
  .hide-responsive.-medium {
    display: none;
  }
}
@media all and (max-width: 75rem) {
  .hide-responsive.-large {
    display: none;
  }
}
/*
---
name: .image-responsive
category: Responsive/Image
tag:
 - responsive
compile: true
---

This class makes the image scaling to its max width.

```jade
img.image-responsive(src='http://www.byui.edu/images/agriculture-life-sciences/flower.jpg')
```

## Variants

`.-full` forces the picture to span to 100% of the parent

```jade
img.image-responsive.-full(src='http://www.byui.edu/images/agriculture-life-sciences/flower.jpg')
```
*/
.image-responsive {
  max-width: 100%;
  height: auto;
  display: block;
}
.image-responsive.-full {
  width: 100%;
}
.image-responsive.-inset {
  max-height: 100%;
}
/*
---
name: .media-responsive
category: Responsive/Media
tag:
 - responsive
compile: true
---

`.media-responsive` - This makes a the `.media-responsive` div a container for any media element (iframe, video, ... ) that responsive scales with a fix ratio.
The media element ( child ) needs the class `.media` to fully fill out the parents size.

The `.media-responsive` component has to predefined modifiers: `.-media-4-3`, `.-media-16-9` that define a fix ratio.

```jade
div.media-responsive.-media-16-9
	iframe.media(src="https://www.youtube.com/embed/TCd6PfxOy0Y")
```

```jade
div.media-responsive.-media-4-3
	iframe.media(src="https://www.youtube.com/embed/TCd6PfxOy0Y")
```

*/
.media-responsive {
  width: 100%;
  position: relative;
  padding-bottom: 50%;
}
.media-responsive.-media-1-1 {
  padding-bottom: 50%;
}
.media-responsive.-media-4-3 {
  padding-bottom: 75%;
}
.media-responsive.-media-16-9 {
  padding-bottom: 56.25%;
}
.media-responsive > .media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/*
---
name: .overflow-text
category: Text
tag:
 - component
 - text
compile: true
---

This component cuts the text on a given height and adds a fadeout effect.
This is usefull for text containers that have more content than space.


```jade
div.overflow-text(style="width: 200px;height: 70px") This is a very long text that is rwaped and cut at the end.
```
*/
.overflow-text {
  overflow: hidden;
  position: relative;
}
.overflow-text:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 2.1875rem;
  background: linear-gradient(to bottom, rgba(255,255,255,0), #fff 70%);
}
/*
---
name: .text-style
category: Text
tag:
 - component
 - text
compile: true
---

A text styling component for different text styles

## Default heading

```jade
div.text-style Default heading
```

## H1
```jade
div.text-style.-h1 The h1 header

```

## H2
```jade
div.text-style.-h2 The h2 header
```

## H3
```jade
div.text-style.-h3 The h3 header
```

## Bold
```jade
div.text-style.-bold Bold text
```

## Align center
```jade
div.text-style.-center Centered text
```

## Align right
```jade
div.text-style.-right Right aligned text
```

*/
.text-style.-h1 {
  font-family: 'Roboto' , serif;
  font-size: 1.25rem;
  line-height: 1.875rem;
}
.text-style.-h2 {
  font-family: 'Roboto' , serif;
  font-size: 1rem;
  line-height: 1.625rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.text-style.-h3 {
  font-family: 'Roboto' , serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-style.-center {
  text-align: center;
}
.text-style.-right {
  text-align: right;
}
.text-style.-bold {
  font-weight: 700;
}
.text-style.-medium {
  font-weight: 500;
}
.text-style.-inverted {
  color: #fff;
}
.text-style.-indent-1 {
  padding-left: 1.875rem;
}
.text-style.-indent-2 {
  padding-left: 3.75rem;
}
.text-style.-indent-3 {
  padding-left: 5.625rem;
}
.text-style.-indent-4 {
  padding-left: 7.5rem;
}
.text-style.-indent-5 {
  padding-left: 9.375rem;
}
.text-style.-indent-6 {
  padding-left: 11.25rem;
}
.collapse-ui > .content.-transition {
  transition: height  ease;
  overflow: hidden;
  height: 0;
}
.collapse-ui.-close > .content {
  display: none;
}
.collapse-ui.-close .collapse-icon {
  transform: rotate(90deg);
}
.collapse-ui .collapse-icon {
  transition: all  ease;
}
@font-face {
  font-family: "Glober-Regular";
  src: url("../Fonts/Glober-Regular.eot") format("eot"), url("../Fonts/Glober-Regular.woff") format("woff"), url("../Fonts/Glober-Regular.woff2") format("woff2"), url("../Fonts/Glober-Regular.ttf") format("ttf");
}
@font-face {
  font-family: "Glober-Semi-Bold";
  src: url("../Fonts/Glober-Semi-Bold.eot") format("eot"), url("../Fonts/Glober-Semi-Bold.woff") format("woff"), url("../Fonts/Glober-Semi-Bold.woff2") format("woff2"), url("../Fonts/Glober-Semi-Bold.ttf") format("ttf");
}
@font-face {
  font-family: "Glober-Bold";
  src: url("../Fonts/Glober-Bold.eot") format("eot"), url("../Fonts/Glober-Bold.woff") format("woff"), url("../Fonts/Glober-Bold.woff2") format("woff2"), url("../Fonts/Glober-Bold.ttf") format("ttf");
}
@font-face {
  font-family: "Glober-Extra-Bold";
  src: url("../Fonts/Glober-Extra-Bold.eot") format("eot"), url("../Fonts/Glober-Extra-Bold.woff") format("woff"), url("../Fonts/Glober-Extra-Bold.woff2") format("woff2"), url("../Fonts/Glober-Extra-Bold.ttf") format("ttf");
}
body {
  text-rendering: optimizeLegibility;
}
h1.-error,
h2.-error,
h3.-error,
h4.-error,
p.-error {
  color: #e94d51;
}
h1.magazine-title {
  font-family: 'Glober-Extra-Bold';
  color: #fff;
  font-size: 5rem;
  line-height: 5.625rem;
  letter-spacing: 0.125rem;
  text-shadow: 0 1px 5px rgba(0,0,0,0) 0;
  text-rendering: optimizeLegibility;
}
h1 {
  font-family: 'Glober-Extra-Bold';
  color: #26262b;
  font-size: 3.125rem;
  line-height: 3.875rem;
  letter-spacing: 0.0625rem;
  text-rendering: optimizeLegibility;
}
h2 {
  font-family: 'Glober-Extra-Bold';
  color: #26262b;
  font-size: 1.875rem;
  line-height: 3rem;
  padding-bottom: 0.625rem;
}
h3 {
  font-family: 'Glober-Extra-Bold';
  color: #26262b;
  font-size: 1.375rem;
  line-height: 2rem;
  padding-bottom: 0.625rem;
  text-rendering: optimizeLegibility;
}
h4 {
  font-family: 'Glober-Extra-Bold';
  color: #26262b;
  font-size: 1.25rem;
  line-height: 1.875rem;
}
.paragraph-element a {
  font-weight: bold;
  color: #009a5b;
  font-size: 1.25rem;
  line-height: 1.875rem;
  text-rendering: optimizeLegibility;
}
.paragraph-element a:hover {
  color: #6b6b6b;
}
a {
  color: #009a5b;
}
a:hover {
  color: #6b6b6b;
}
.p-normal,
ol,
ul,
.text-style {
  font-family: 'Glober-Regular';
  color: #26262b;
  font-size: 1.25rem;
  line-height: 1.875rem;
  text-rendering: optimizeLegibility;
}
ul,
ol {
  margin-left: 1.7rem;
}
p.-bold,
.p-bold {
  font-family: 'Glober-Extra-Bold';
  color: #26262b;
  font-size: 1rem;
  line-height: 1.4375rem;
}
p.-big,
.p-big {
  font-family: 'Glober-Regular';
  color: #26262b;
  font-size: 1.5rem;
  line-height: 2rem;
}
p.-small,
.p-small {
  font-family: 'Glober-Regular';
  color: #26262b;
  font-size: 0.9375rem;
  line-height: 1.3125rem;
  padding-bottom: 3.75rem;
}
.footer-text {
  font-family: 'Glober-Semi-Bold';
  color: #26262b;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  text-rendering: optimizeLegibility;
}
.footer-heading {
  color: #fff;
  font-family: 'Glober-Extra-Bold';
  font-size: 0.9375rem;
  line-height: 1.5rem;
  padding-bottom: 0.9375rem;
  text-rendering: optimizeLegibility;
}
input,
textarea {
  font-family: 'Glober-Semi-Bold';
  color: #6b6b6b;
  font-size: 1rem;
  line-height: 1.625rem;
  text-rendering: optimizeLegibility;
}
input:active,
textarea:active {
  color: #009a5b;
}
input.-disabled,
textarea.-disabled {
  color: #9c9b9b;
}
.tag-text {
  font-family: 'Glober-Bold';
  color: #6b6b6b;
  font-size: 1rem;
  line-height: 1.625rem;
  text-rendering: optimizeLegibility;
}
.label-text {
  font-family: 'Glober-Extra-Bold';
  color: #26262b;
  font-size: 1rem;
  line-height: 1.5rem;
  text-rendering: optimizeLegibility;
}
.label-text.-library {
  font-family: 'Glober-Regular';
}
.filter-text {
  font-family: 'Glober-Bold';
  color: #6b6b6b;
  font-size: 1rem;
  line-height: 1.5rem;
  text-rendering: optimizeLegibility;
}
.navigation-text {
  font-family: 'Glober-Bold';
  color: #26262b;
  font-size: 1rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.125rem;
  text-rendering: optimizeLegibility;
}
.caption-text {
  font-family: 'Glober-Extra-Bold';
  color: #26262b;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  text-align: center;
  text-rendering: optimizeLegibility;
}
.link-button,
.link-button a {
  font-family: 'Glober-Bold';
  color: #6b6b6b;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.4375rem;
  letter-spacing: 0.125rem;
  text-rendering: optimizeLegibility;
}
.button-text {
  font-family: 'Glober-Extra-Bold';
  font-size: 1rem;
  line-height: 1.4375rem;
  letter-spacing: 0.125rem;
  text-rendering: optimizeLegibility;
}
@media only screen and (max-width: 48rem) {
  h1.magazine-title {
    font-size: 2.5rem;
    line-height: 3rem;
    letter-spacing: 0.0625rem;
  }
  h1 {
    font-size: 2.25rem;
    line-height: 2.875rem;
    letter-spacing: 0.04375rem;
  }
  h2 {
    font-size: 1.625rem;
    line-height: 2.25rem;
    padding-bottom: 0.5rem;
  }
  h3 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding-bottom: 0.5rem;
  }
  h4 {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
  .p-normal,
  ol,
  ul,
  .text-style {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
  ul,
  ol {
    margin-left: 1.7rem;
  }
  p.-big,
  .p-big {
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
  .paragraph-element a {
    font-size: 0.9375rem;
    line-height: 1.3125rem;
  }
}
.cart-component .button .svg-icon {
  width: 20px;
}
@media only screen and (max-width: 48rem) {
  .cart-component {
    margin-bottom: 10px;
  }
}
.dropdown-filter {
  width: 100%;
  display: none;
  margin: 0.625rem auto;
}
.dropdown-filter >.description {
  margin-bottom: 0.625rem;
}
.dropdown-filter >.description a {
  color: #009a5b;
}
.dropdown-filter >.description a:hover {
  color: #26262b;
}
.dropdown-filter >.drop-down >.toggle {
  border: 1px solid #6b6b6b;
  padding: 0.3125rem;
}
.dropdown-filter >.drop-down.-open >.toggle {
  border-bottom: none;
}
.dropdown-filter >.drop-down >.drop-down-menu {
  background-color: #fff;
  border: 1px solid #6b6b6b;
  border-top: none;
  width: 100%;
}
.dropdown-filter .value {
  overflow: hidden;
  padding: 0.3125rem;
}
.dropdown-filter .value:hover {
  color: #009a5b;
}
.dropdown-filter .svg-icon {
  color: #009a5b;
}
.dropdown-filter .svg-icon.-toggle {
  color: #6b6b6b;
  width: 0.625rem;
  height: 0.375rem;
}
@media only screen and (max-width: 48rem) {
  .dropdown-filter {
    display: block;
  }
  .dropdown-filter >.drop-down {
    display: block;
    margin: 0 auto;
  }
  .dropdown-filter >.drop-down >.toggle {
    display: block;
  }
}
.element-layout {
  max-width: 62.5rem;
  width: 100%; /* IE11 fix */
  margin-right: auto;
  margin-left: auto;
  padding: 0 1rem;
}
.element-layout.-small {
  max-width: 50rem;
}
.element-layout.-full {
  width: 100%;
  max-width: 100%;
}
.feedback-form .autocomplete >.value {
  cursor: pointer;
  padding-top: 5px;
  border-top: 1px solid #9c9b9b;
}
.footer-component {
  color: #fff;
  padding: 1.875rem 8.75rem;
  background-color: #26262b;
}
.footer-component .mailto,
.footer-component .impressum,
.footer-component .phone {
  color: #fff;
}
.footer-component .mailto:hover,
.footer-component .impressum:hover,
.footer-component .phone:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 48rem) {
  .footer-component {
    padding: 1.875rem 4.375rem;
  }
  .footer-component .-location,
  .footer-component .-phone,
  .footer-component .-mail,
  .footer-component .impressum,
  .footer-component .footer-heading {
    display: block;
    text-align: center;
  }
  .footer-component .logo {
    display: flex;
    justify-content: center;
  }
  .footer-component .-spacer {
    display: none;
  }
  .footer-component .-phone,
  .footer-component .-mail,
  .footer-component .-impressum {
    padding-bottom: 1.25rem;
  }
}
.form-component .input {
  background-color: #f8f8f8;
  color: #000;
  border: 2px solid #a9a9a9;
  padding: 0.625rem;
  font-size: 1.25rem;
  width: 12.5rem;
  margin-bottom: 1rem;
}
.form-component .button {
  width: 8.125rem;
  line-height: 0.8;
  margin-bottom: 1rem;
}
.form-component .button:focus {
  box-shadow: 0 0 3pt 2pt #009a5b;
  outline: none;
  border: none;
}
.level-box {
  display: flex;
  align-items: center;
}
.level-box >.box {
  width: 20px;
  height: 20px;
  margin-right: 0.3125rem;
  border: 2px solid #009fe3;
  display: inline-block;
}
.level-box >.box.-filled {
  background-color: #009fe3;
}
.meta-box {
  padding: 3.125rem 0;
}
.meta-box.-editor .task-menu >.add {
  display: none;
}
@media only screen and (max-width: 48rem) {
  .meta-box {
    padding: 2.5rem 0;
  }
  .meta-box.-editor {
    display: none;
  }
}
@page {
  margin: 1cm;
}
@media print {
  .pdf-license {
    page-break-after: always;
  }
}
.ie-fixMinHeight {
  display: flex;
}
.ie-fixMinHeight .page {
  min-height: 100vh;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.ie-fixMinHeight .page .content {
  flex-grow: 1;
}
.teacher-comment {
  display: flex;
  padding-top: 1.875rem;
}
.teacher-comment > .download {
  display: flex;
}
.teacher-comment > .download > svg {
  margin-right: 1rem;
}
.zikzak-logo-white {
  display: flex;
}
.zikzak-logo-white > img {
  height: 3.125rem;
}
.zikzak-logo {
  display: flex;
}
.zikzak-logo > img {
  height: 3.125rem;
}
/*
---
name: .padding-box
category: Box/Padding
tag:
 - component
 - layout
compile: true
---

A simple component to add paddings

## Applying paddings

The easiest use case is to use simple `.padding-box .-all` for a padding on top, right, bottom and left with default size or
`.-top`, `.-right`, `.-bottom` and `.-left` for specified sides

```jade
div.aigis-debug.padding-box.-all Defaut padding applied on top, right, bottom and left
```
```jade
div.aigis-debug.padding-box.-bottom Defaut padding applied on bottom
```

## Variants

The variants for the paddings are `.-top-{size}`, `.-right-{size}`, `.-bottom-{size}` and `.left-{size}` together with sizes small, medium or large.
All sizes are defined in the variable theme.components.padding-box. Values are `small`, `medium`, and `large`

```jade
div.aigis-debug.padding-box.-left-small Small padding applied on left
```
```jade
div.aigis-debug.padding-box.-right-small(style="text-align:right") Small padding applied on right
```
```jade
div.aigis-debug.padding-box.-top-small Small padding applied on top
```
```jade
div.aigis-debug.padding-box.-bottom-small Small padding applied on bottom
```

There are also shortcuts for vertical `.-v-{size}` and horizontal `.-h-{size}` together with sizes

```jade
div.aigis-debug.padding-box.-v-medium Medium padding applied on top and bottom
```
```jade
div.aigis-debug.padding-box.-h-medium(style="width: 300px") Medium padding applied on right and left
```
```jade
div.aigis-debug.padding-box.-h-medium.-top-large(style="width: 400px") Medium padding applied on right and left, large padding applied on top
```
*/
.padding-box.-top {
  padding-top: 0.625rem;
}
.padding-box.-right {
  padding-right: 0.625rem;
}
.padding-box.-bottom {
  padding-bottom: 0.625rem;
}
.padding-box.-left {
  padding-left: 0.625rem;
}
.padding-box.-h {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.padding-box.-v {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.padding-box.-all {
  padding: 0.625rem 0.625rem;
}
.padding-box.-top-small {
  padding-top: 0.125rem;
}
.padding-box.-right-small {
  padding-right: 0.125rem;
}
.padding-box.-bottom-small {
  padding-bottom: 0.125rem;
}
.padding-box.-left-small {
  padding-left: 0.125rem;
}
.padding-box.-h-small {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}
.padding-box.-v-small {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.padding-box.-all-small {
  padding: 0.125rem 0.125rem;
}
.padding-box.-top-medium {
  padding-top: 1rem;
}
.padding-box.-right-medium {
  padding-right: 1rem;
}
.padding-box.-bottom-medium {
  padding-bottom: 1rem;
}
.padding-box.-left-medium {
  padding-left: 1rem;
}
.padding-box.-h-medium {
  padding-left: 1rem;
  padding-right: 1rem;
}
.padding-box.-v-medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.padding-box.-all-medium {
  padding: 1rem 1rem;
}
.padding-box.-top-large {
  padding-top: 2rem;
}
.padding-box.-right-large {
  padding-right: 2rem;
}
.padding-box.-bottom-large {
  padding-bottom: 2rem;
}
.padding-box.-left-large {
  padding-left: 2rem;
}
.padding-box.-h-large {
  padding-left: 2rem;
  padding-right: 2rem;
}
.padding-box.-v-large {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.padding-box.-all-large {
  padding: 2rem 2rem;
}
.padding-box.-gutter {
  padding: 0.9375rem 0.9375rem;
}
.button-back {
  height: 2.5rem;
  background-color: transparent;
  color: #6b6b6b;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-back:hover {
  color: #009a5b;
}
.button-back > .svg-icon {
  fill: #009a5b;
  margin-top: 0.4375rem;
}
.button-general {
  height: 2.5rem;
  border-radius: 1.25rem;
  padding: 0.625rem 0.9375rem;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-primary {
  background-color: #009a5b;
  border: none;
}
.button-primary:hover {
  background-color: #037848;
}
.button-primary.-disabled {
  cursor: default;
  background-color: #9c9b9b;
  color: #26262b;
}
.button-secondary {
  background-color: #fff;
  border: 2px solid #009a5b;
  color: #009a5b;
}
.button-secondary:hover {
  background-color: #009a5b;
  color: #fff;
}
.button-secondary.-active {
  background-color: #009a5b;
  color: #fff;
}
.link-button > a:hover {
  color: #009a5b;
}
.cart-component .button .svg-icon {
  width: 20px;
}
@media only screen and (max-width: 48rem) {
  .cart-component {
    margin-bottom: 10px;
  }
}
.collapse-ui {
  cursor: pointer;
}
.collapse-ui >.content {
  cursor: auto;
}
.collapse-ui >.toggle {
  border-bottom: 2px solid #26262b;
}
.collapse-ui >.toggle > .collapse-icon {
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("../images/icon-minus.svg");
}
.collapse-ui >.toggle > .collapse-icon:hover {
  background-image: url("../images/icon-minus-grey.svg");
}
.collapse-ui.-close > .toggle > .collapse-icon {
  background-image: url("../images/icon-plus.svg");
}
.collapse-ui.-close > .toggle > .collapse-icon:hover {
  background-image: url("../images/icon-plus-grey.svg");
}
.contents-table .element:nth-child(even) {
  flex-direction: row-reverse;
}
.contents-table .element {
  margin-bottom: 2.25rem;
}
.contents-table .element .image {
  width: 100%;
}
.contents-table .element .page-row {
  align-items: center;
}
.contents-table .element .pagenumber {
  color: #009a5b;
  font-family: Glober-Extra-Bold;
  line-height: 3.875rem;
  font-size: 3.125rem;
  letter-spacing: 0.0625rem;
}
.image-element {
  padding-bottom: 3.75rem;
}
.image-element > .image {
  min-width: 100%;
}
.image-element > .caption {
  padding-top: 0.625rem;
}
.license-content {
  margin-bottom: 7.5rem;
}
.license-content.-auth .auth .dialog {
  background-color: #f8f8f8;
  padding-top: 9.375rem;
  padding-bottom: 3.75rem;
  margin-bottom: 3.75rem;
}
.license-content.-noauth .auth {
  position: relative;
}
.license-content.-noauth .auth .overlay {
  position: absolute;
  z-index: 50;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background-color: #fff;
}
.license-content.-noauth .noauth {
  background-color: #dcecdc;
  padding-top: 9.375rem;
  padding-bottom: 3.75rem;
  margin-bottom: 3.75rem;
}
.license-content .button {
  display: inline-block;
}
.license-content .cart-component >.button {
  width: 200px;
  display: flex;
}
.license-content .link {
  border-bottom: 1px solid #009a5b;
  color: #009a5b;
  text-rendering: optimizeLegibility;
}
.license-content .link:hover {
  color: #6b6b6b;
}
.license-content .user-licenses >.table {
  width: 100%;
}
.license-content .user-licenses >.table th {
  text-align: left;
}
.license-content .user-licenses >.table td,
.license-content .user-licenses >.table th {
  padding: 0.25rem;
}
.overview-content {
  margin-top: 9.375rem;
}
.paragraph-element {
  padding-bottom: 3.75rem;
}
.paragraph-element ul >li,
.paragraph-element ol >li {
  padding: 0.3125rem;
}
.schoolpage-content {
  margin-top: 9.375rem;
}
.schoolpage-content.element-layout {
  max-width: 43.75rem;
}
.singlepage-content {
  margin-top: 9.375rem;
  margin-bottom: 7.5rem;
}
.slide-container {
  padding-bottom: 3.75rem;
}
.startpage-content {
  padding-top: 4rem;
}
.startpage-content >.subheader {
  background-color: #ffd226;
}
.startpage-content >.subheader .award {
  max-width: 1100px;
  margin: 0 auto;
}
.startpage-content >.subheader .award .container {
  margin-left: 16px;
}
.startpage-content >.subheader .award .image {
  display: inline-block;
}
.startpage-content >.subheader .award .pokal {
  height: 50px;
  margin-right: 32px;
}
.startpage-content >.subheader .award .worlddidac {
  height: 32px;
  margin-right: 16px;
}
.startpage-content >.subheader .award .text {
  font-size: 22px;
  line-height: 30px;
  font-family: 'Glober-Semi-Bold';
}
.startpage-content .intro {
  background-color: #f8f8f8;
  padding-top: 6.25rem;
}
.startpage-content .video-element {
  max-width: 62.5rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 1rem;
  margin-bottom: 3.75rem;
}
@media only screen and (max-width: 48rem) {
  .startpage-content >.subheader .award {
    display: block;
  }
  .startpage-content >.subheader .award .container {
    margin-bottom: 16px;
    margin-left: 16px;
  }
  .startpage-content >.subheader .award .text {
    font-size: 14px;
    line-height: 20px;
    font-family: 'Glober-Semi-Bold';
    margin-left: 16px;
  }
  .startpage-content .video-element {
    margin-bottom: 2.5rem;
  }
}
.startpage-contents .contents-row .item {
  padding: 5px;
}
.startpage-contents .contents-row .pagenumber {
  width: 2rem;
  font-weight: bold;
}
.video-element {
  padding-bottom: 3.75rem;
}
.video-element > .caption {
  padding-top: 0.625rem;
}
.year-header {
  border-bottom: solid 2px #6b6b6b;
  padding-bottom: 1.875rem;
  margin-top: 4.6875rem;
  margin-bottom: 1.875rem;
}
.dropdown-filter {
  width: 100%;
  display: none;
  margin: 0.625rem auto;
}
.dropdown-filter >.description {
  margin-bottom: 0.625rem;
}
.dropdown-filter >.description a {
  color: #009a5b;
}
.dropdown-filter >.description a:hover {
  color: #26262b;
}
.dropdown-filter >.drop-down >.toggle {
  border: 1px solid #6b6b6b;
  padding: 0.3125rem;
}
.dropdown-filter >.drop-down.-open >.toggle {
  border-bottom: none;
}
.dropdown-filter >.drop-down >.drop-down-menu {
  background-color: #fff;
  border: 1px solid #6b6b6b;
  border-top: none;
  width: 100%;
}
.dropdown-filter .value {
  overflow: hidden;
  padding: 0.3125rem;
}
.dropdown-filter .value:hover {
  color: #009a5b;
}
.dropdown-filter .svg-icon {
  color: #009a5b;
}
.dropdown-filter .svg-icon.-toggle {
  color: #6b6b6b;
  width: 0.625rem;
  height: 0.375rem;
}
@media only screen and (max-width: 48rem) {
  .dropdown-filter {
    display: block;
  }
  .dropdown-filter >.drop-down {
    display: block;
    margin: 0 auto;
  }
  .dropdown-filter >.drop-down >.toggle {
    display: block;
  }
}
.editor-content dl {
  display: flex;
  flex-flow: row wrap;
}
.editor-content dt {
  flex-basis: 30%;
  padding: 2px 4px;
  background: #333;
  margin-right: 2px;
  margin-bottom: 2px;
  background-color: #fff;
}
.editor-content dt.customer {
  margin-top: 1rem;
}
.editor-content dd {
  flex-basis: 60%;
  flex-grow: 1;
  margin-bottom: 2px;
  padding: 2px 4px;
  background-color: #fff;
}
.editor-content dd.customer {
  margin-top: 1rem;
}
.editor-content .form-component .input {
  margin: 0;
}
.editor-content .autocomplete-component {
  border: 1px solid #000;
}
.pagination {
  list-style: none;
  text-align: center;
  margin-bottom: 10px;
}
.pagination li {
  display: inline;
  padding: 0.3125rem;
}
.state-symbol.-redeemed {
  background-color: #009a5b;
}
.state-symbol.-unredeemed {
  background-color: #6b6b6b;
}
.state-symbol.-deactivated {
  background-color: #e94d51;
}
.element-layout {
  max-width: 62.5rem;
  width: 100%; /* IE11 fix */
  margin-right: auto;
  margin-left: auto;
  padding: 0 1rem;
}
.element-layout.-small {
  max-width: 50rem;
}
.element-layout.-full {
  width: 100%;
  max-width: 100%;
}
.feedback-form .autocomplete >.value {
  cursor: pointer;
  padding-top: 5px;
  border-top: 1px solid #9c9b9b;
}
.footer-component {
  color: #fff;
  padding: 1.875rem 8.75rem;
  background-color: #26262b;
}
.footer-component .mailto,
.footer-component .impressum,
.footer-component .phone {
  color: #fff;
}
.footer-component .mailto:hover,
.footer-component .impressum:hover,
.footer-component .phone:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 48rem) {
  .footer-component {
    padding: 1.875rem 4.375rem;
  }
  .footer-component .-location,
  .footer-component .-phone,
  .footer-component .-mail,
  .footer-component .impressum,
  .footer-component .footer-heading {
    display: block;
    text-align: center;
  }
  .footer-component .logo {
    display: flex;
    justify-content: center;
  }
  .footer-component .-spacer {
    display: none;
  }
  .footer-component .-phone,
  .footer-component .-mail,
  .footer-component .-impressum {
    padding-bottom: 1.25rem;
  }
}
.form-component .input {
  background-color: #f8f8f8;
  color: #000;
  border: 2px solid #a9a9a9;
  padding: 0.625rem;
  font-size: 1.25rem;
  width: 12.5rem;
  margin-bottom: 1rem;
}
.form-component .button {
  width: 8.125rem;
  line-height: 0.8;
  margin-bottom: 1rem;
}
.form-component .button:focus {
  box-shadow: 0 0 3pt 2pt #009a5b;
  outline: none;
  border: none;
}
.answer-container {
  padding-bottom: 1.5625rem;
}
.answer-container > .input {
  margin-right: 0.9375rem;
  flex: none;
}
.dropdown-text > .select {
  background-color: #f8f8f8;
  border: solid 1px #9c9b9b;
}
.feedback-box > .feedback-panel {
  display: flex;
  align-items: center;
}
.feedback-box > .feedback-panel > .icon {
  display: inline-block;
  min-width: 2.5rem;
  height: 2.5rem;
  margin-right: 1.25rem;
}
.feedback-box.-wrong.select {
  border: 1px solid #ea5762;
  background-color: #ffe0e2;
}
.feedback-box.-wrong > .answer-container > .text {
  color: #ea5762;
}
.feedback-box.-wrong > .feedback-panel > .icon {
  background-image: url("../images/icon-wrong.svg");
}
.feedback-box.-correct.select {
  border: 1px solid #009a5b;
  background-color: #dcecdc;
}
.feedback-box.-correct > .answer-container > .text {
  color: #009a5b;
}
.feedback-box.-correct > .feedback-panel > .icon {
  background-image: url("../images/icon-correct.svg");
}
.feedback-container {
  display: inline-block;
  padding-top: 2.1875rem;
}
.feedback-panel {
  background-color: #f8f8f8;
}
.hotspot-image {
  min-width: 100%;
  margin-bottom: 0.625rem;
}
.hotspot-point {
  background-color: #6b6b6b;
  border-radius: 50%;
  width: 1.5625rem;
  height: 1.5625rem;
  opacity: 0.6;
}
.hotspot-point.-wrong {
  background-color: #ea5762;
}
.hotspot-point.-correct {
  background-color: #009a5b;
}
.hotspot-question {
  padding-bottom: 1.25rem;
}
.question-container {
  padding-bottom: 1.5625rem;
}
.level-box {
  display: flex;
  align-items: center;
}
.level-box >.box {
  width: 20px;
  height: 20px;
  margin-right: 0.3125rem;
  border: 2px solid #009fe3;
  display: inline-block;
}
.level-box >.box.-filled {
  background-color: #009fe3;
}
.add-license >.input {
  padding: 5px;
  font-size: 1.875rem;
  width: 6.25rem;
}
.add-license .button-general {
  line-height: 0.8px;
}
.magazine-header >.cover-image {
  position: relative;
  height: 460px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.magazine-header >.cover-image >.transparent {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  z-index: 5;
}
.magazine-header >.cover-image > .content {
  position: absolute;
  z-index: 10;
  width: 100%;
  padding-top: 14rem;
}
.magazine-header >.cover-image > .content > .date {
  color: #fff;
}
.magazine-header >.cover-image.-mobile {
  display: none;
}
@media only screen and (max-width: 48rem) {
  .magazine-header >.cover-image {
    display: none;
  }
  .magazine-header >.cover-image.-mobile {
    display: block;
    height: 300px;
  }
  .magazine-header >.cover-image.-mobile > .content {
    padding-top: 8rem;
  }
}
.magazine-page {
  margin-bottom: 7.5rem;
}
.magazine-page.-student .magazine-description,
.magazine-page.-student .teacher-comment,
.magazine-page.-student .unit-menu,
.magazine-page.-student .meta-box,
.magazine-page.-student .dropdown-filter {
  display: none;
}
.magazine-preview {
  text-align: center;
  width: 100%;
}
.magazine-preview.-hidden {
  display: none;
}
.magazine-preview >.image-container {
  position: relative;
  display: inline-block;
  text-align: left;
}
.magazine-preview >.image-container .preview-icon {
  color: #009a5b;
}
.magazine-preview >.image-container .future-publication {
  position: absolute;
  top: 0px;
  width: 100%;
  background-color: #009fe3;
  color: #fff;
  line-height: 3rem;
  text-align: center;
}
.magazine-preview:hover .preview .preview-icon {
  display: none;
}
.magazine-preview:hover .preview .preview-icon.-hover {
  display: inline-block;
}
.magazine-preview:hover .image {
  box-shadow: 0px 2px 16px rgba(107,107,107,0.5);
  transition: box-shadow 0.3s;
}
.magazine-preview .image {
  box-shadow: 0 2px 4px 0 rgba(107,107,107,0.3);
  max-height: 20rem;
}
.magazine-preview .date {
  padding-top: 0.9375rem;
}
.magazine-preview .preview {
  text-align: center;
  position: absolute;
  top: 130px;
  transform: translate(-50%, 0%);
  left: 50%;
}
.magazine-preview .preview .preview-icon {
  width: 60px;
  height: 60px;
}
.magazine-preview .preview .preview-icon.-hover {
  display: none;
}
.magazine-preview .preview .text {
  color: #009a5b;
  font-size: 1rem;
  font-weight: bold;
}
.magazine-preview.-future .image-container .cart-component {
  bottom: 10px;
}
.magazine-preview.-disabled .image-container .image,
.magazine-preview.-disabled .image-container .date,
.magazine-preview.-disabled .image-container .title {
  opacity: 0.5;
}
.meta-box {
  padding: 3.125rem 0;
}
.meta-box.-editor .task-menu >.add {
  display: none;
}
@media only screen and (max-width: 48rem) {
  .meta-box {
    padding: 2.5rem 0;
  }
  .meta-box.-editor {
    display: none;
  }
}
.mobilenavigation-mainheader {
  display: none;
  text-align: right;
  align-items: center;
  justify-content: flex-end;
}
.mobilenavigation-mainheader > a {
  color: #26262b;
}
@media only screen and (max-width: 61.25rem) {
  .mobilenavigation-mainheader {
    display: flex;
  }
}
.mobilenavigation-menu {
  position: absolute;
  top: 4.4375rem;
  min-width: 30%;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 5px 7px 0px rgba(38,38,43,0.1);
  max-width: 23.4375rem;
  padding: 2.8125rem 1rem;
}
.mobilenavigation-menu > a.-showall,
.mobilenavigation-menu a.-about,
.mobilenavigation-menu a.-license {
  color: #26262b;
  justify-content: flex-start;
}
.mobilenavigation-menu > a.-showall:hover,
.mobilenavigation-menu a.-about:hover,
.mobilenavigation-menu a.-license:hover {
  color: #009a5b;
}
.mobilenavigation-menu > a.-showall,
.mobilenavigation-menu > a.-about {
  padding-bottom: 1.25rem;
}
.mobilenavigation-menu > a.-license {
  padding-bottom: 3.75rem;
}
.navigation-link {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.navigation-link > .icon {
  display: flex;
}
.navigation-link > .icon svg {
  color: #009a5b;
  width: 1.875rem;
  height: 1.875rem;
}
.navigation-link > .icon svg.-open,
.navigation-link > .icon svg.-close {
  margin: 0.625rem;
}
.navigation-link > .text {
  margin-left: 0.9375rem;
}
.navigation-link:hover .text {
  color: #009a5b;
}
.navigation-link .svg-icon.-inline.-close {
  display: none;
}
.navigation-link.-open .svg-icon.-inline.-close {
  display: inline-block;
}
.navigation-link.-open .svg-icon.-inline.-open {
  display: none;
}
.navigation-link.-active >.text {
  border-bottom: 2px solid #009a5b;
  color: #009a5b;
}
.navigation-main {
  background-color: #fff;
  padding: 0.625rem 1.875rem 0.625rem 1.875rem;
  box-shadow: 0 2px 8px 0 rgba(38,38,43,0.1);
  z-index: 75;
}
.navigation-main.-transparent {
  background-color: rgba(0,0,0,0);
  box-shadow: none;
}
.navigation-main.-transparent .navigation-link {
  color: #fff;
}
.navigation-main.-transparent .navigation-link > .icon svg {
  color: #fff;
}
.navigation-main.-transparent .button-secondary {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
}
.navigation-main.-transparent .zikzak-logo-white {
  display: block;
}
.navigation-main.-transparent .zikzak-logo {
  display: none;
}
.navigation-main.-transparent .mobilenavigation-menu .navigation-link {
  color: #000;
}
.navigation-main.-transparent .mobilenavigation-menu .navigation-link .button-secondary {
  background-color: #fff;
  border: 2px solid #009a5b;
  color: #009a5b;
}
.navigation-main.-transparent .mobilenavigation-menu .navigation-link .button-secondary:hover {
  background-color: #009a5b;
  color: #fff;
}
.navigation-main.-transparent .mobilenavigation-menu .navigation-link:hover {
  color: #009a5b;
}
.navigation-main.-transparent .mobilenavigation-menu .navigation-link > .icon svg {
  color: #009a5b;
}
.navigation-main .zikzak-logo-white {
  display: none;
}
.navigation-main .-right {
  text-align: right;
}
.navigation-main.-schoolpage .navigation-mainheader,
.navigation-main.-schoolpage .mobilenavigation-mainheader {
  display: none;
}
.navigation-main.-overview,
.navigation-main.-startpage,
.navigation-main.-schoolpage {
  box-shadow: none;
}
.navigation-main.-overview .mobilenavigation-menu,
.navigation-main.-startpage .mobilenavigation-menu,
.navigation-main.-schoolpage .mobilenavigation-menu {
  box-shadow: none;
  border-left: 1px solid #9c9b9b;
  border-bottom: 1px solid #9c9b9b;
}
@media only screen and (max-width: 61.25rem) {
  .navigation-main {
    padding: 0.625rem 1.25rem 0.625rem 1.875rem;
  }
  .navigation-main.-overview,
  .navigation-main.-startpage,
  .navigation-main.-schoolpage {
    border-bottom: 1px solid #9c9b9b;
  }
}
.navigation-mainheader {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.navigation-mainheader > .navigation-link {
  margin-right: 1.5rem;
}
.navigation-mainheader > a {
  color: #26262b;
}
@media only screen and (max-width: 61.25rem) {
  .navigation-mainheader {
    display: none;
  }
}
.navigation-unit {
  background-color: #fff;
  padding: 0.625rem 1.875rem 0.625rem 1.875rem;
  box-shadow: 0 2px 8px 0 rgba(38,38,43,0.1);
  z-index: 10;
}
.navigation-unit .-right {
  text-align: right;
}
.navigation-unit .back {
  width: min-content;
}
.navigation-unit .stupidIE {
  display: -ms-grid;
  -ms-grid-columns: min-content;
}
.navigation-unitheader {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.navigation-unitheader > a {
  color: #26262b;
}
.navigation-unitheader > .close {
  padding-left: 1.875rem;
}
.navigation-unitheader > .close > svg {
  width: 2.5rem;
  height: 2.5rem;
}
@media only screen and (max-width: 48rem) {
  .navigation-unitheader > .download > .downloadtext {
    display: none;
  }
  .navigation-unitheader > .close {
    padding-left: 1.25rem;
  }
}
.preview-overview >.start-magazineoverview >.element-layout {
  padding: 0;
}
.preview-page .level-image {
  width: 100%;
}
.preview-page .level-image.-desktop {
  display: block;
}
.preview-page .level-image.-mobile {
  display: none;
}
@media only screen and (max-width: 32.5rem) {
  .preview-page .image.-desktop {
    display: none;
  }
  .preview-page .image.-mobile {
    display: block;
  }
}
.preview-switch {
  text-transform: uppercase;
  padding: 3.4375rem 0;
  background-color: #f2f8fd;
}
.preview-switch .toggle {
  width: 64px;
  height: 40px;
  margin: 0 16px;
}
.preview-switch .preview,
.preview-switch .magazine {
  display: inline-block;
  color: #6b6b6b;
}
.preview-switch .preview.-active,
.preview-switch .magazine.-active {
  color: #009a5b;
}
.preview-switch .preview:hover,
.preview-switch .magazine:hover {
  color: #009a5b;
}
.preview-switch .cart-component {
  display: inline-block;
}
.preview-switch .cart-component .button {
  width: 150px;
  float: right;
}
@media only screen and (max-width: 48rem) {
  .preview-switch.navigation-text {
    font-size: 0.875rem;
  }
  .preview-switch {
    display: flex;
    justify-content: center;
  }
  .preview-switch >.element-layout {
    width: unset;
  }
  .preview-switch .cart-component {
    display: none;
  }
}
@page {
  margin: 1cm;
}
@media print {
  .pdf-license {
    page-break-after: always;
  }
}
.start-arrownavigation .svg-icon.-right {
  transform: rotate(180deg);
}
.start-arrownavigation .previous,
.start-arrownavigation .next {
  display: none;
  cursor: pointer;
  font-size: 5rem;
  font-weight: bold;
  color: #009a5b;
}
@media only screen and (max-width: 61.25rem) {
  .start-arrownavigation {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .start-arrownavigation .previous,
  .start-arrownavigation .next {
    display: block;
    transform: translate(0%, 160px);
  }
  .start-arrownavigation .previous,
  .start-arrownavigation .next,
  .start-arrownavigation .elements {
    flex: 1 1 auto;
    text-align: center;
  }
}
.start-licenseinstruction {
  background-color: #009fe3;
  color: #fff;
}
.start-licenseinstruction .p-big,
.start-licenseinstruction h2 {
  color: #fff;
}
.start-licenseinstruction .svg-icon.cart {
  width: 4.375rem;
  height: 4.0625rem;
}
.start-licenseinstruction .svg-icon.url {
  width: 8.625rem;
  height: 4.0625rem;
}
.start-licenseinstruction .svg-icon.code {
  width: 4.375rem;
  height: 4.0625rem;
}
.start-licenseinstruction .svg-icon.book {
  width: 4.375rem;
  height: 4.0625rem;
}
.start-licenseinstruction .arrow {
  margin-top: 1.25rem;
}
.start-licenseinstruction .elements {
  margin-top: 2.5rem;
}
.start-licenseinstruction .elements .element {
  text-align: center;
}
.start-licenseinstruction .elements .element .text {
  color: #fff;
}
.start-licenseinstruction .container {
  padding: 3.75rem 1rem;
}
@media only screen and (max-width: 48rem) {
  .start-licenseinstruction .elements {
    margin-top: 1.875rem;
  }
  .start-licenseinstruction .elements .element {
    text-align: center;
  }
  .start-licenseinstruction .elements .arrow {
    transform: rotate(90deg);
    margin-bottom: 1.25rem;
  }
  .start-licenseinstruction .elements .url,
  .start-licenseinstruction .elements .code {
    margin-bottom: 2.3125rem;
  }
  .start-licenseinstruction .container {
    padding: 3.75rem 0rem;
  }
}
.start-magazineoverview {
  margin-bottom: 3.75rem;
  position: relative;
}
.start-magazineoverview .start-pointnavigation {
  bottom: 100px;
  display: none;
}
@media only screen and (max-width: 61.25rem) {
  .start-magazineoverview {
    margin-bottom: 2.5rem;
  }
  .start-magazineoverview .description {
    display: none;
  }
  .start-magazineoverview .start-pointnavigation {
    display: block;
  }
  .start-magazineoverview .button-container {
    text-align: center;
  }
  .start-magazineoverview .buttons {
    display: inline-block;
  }
  .start-magazineoverview .buttons >.order {
    margin: 0 auto 0.9375rem auto;
  }
}
.start-pointnavigation {
  text-align: center;
  width: 100px;
  display: inline-block;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0%);
}
.start-pointnavigation.-hidden {
  display: none;
}
.start-pointnavigation .point {
  display: inline-block;
  cursor: pointer;
  margin: 5px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: #f1f0f1;
}
.start-pointnavigation .point.-active {
  background-color: #9c9b9b;
}
.start-teaser {
  margin-bottom: 3.75rem;
}
.start-teaser .download .svg-icon {
  width: 17px;
  height: 14px;
}
.start-teaser .download .text {
  height: 1.4375rem;
  color: #6b6b6b;
  font-family: Glober-Bold;
  font-size: 1rem;
  letter-spacing: 0.125rem;
  line-height: 1.4375rem;
}
.start-teaser .download a:hover .text {
  color: #009a5b;
}
.start-teaser .cover .image-container {
  position: relative;
  display: block;
}
.start-teaser .cover .image-container .preview {
  display: none;
  color: #009a5b;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 10;
  left: 50%;
  top: 50%;
  text-align: center;
}
.start-teaser .cover .image-container .preview .preview-icon {
  width: 60px;
  height: 60px;
}
.start-teaser .cover .image-container .preview .text {
  color: #009a5b;
  font-size: 1rem;
  font-weight: bold;
}
.start-teaser .cover .image-container .image {
  width: 100%;
}
.start-teaser .cover .image-container:hover .preview {
  display: block;
}
.start-teaser .cover .image-container:hover .image {
  box-shadow: 0px 2px 16px rgba(107,107,107,0.5);
  opacity: 0.5;
  transition: box-shadow 0.3s, opacity 0.3s;
}
.start-teaser .grid-layout.-gutter > .item.infos {
  padding-left: 100px;
}
.start-teaser .grid-layout.-gutter > .item.infos > .date {
  margin-bottom: 0.9375rem;
}
.start-teaser .grid-layout.-gutter > .item.infos > .title {
  margin-bottom: 0.9375rem;
}
.start-teaser .grid-layout.-gutter > .item.infos > .description a {
  color: #009a5b;
  text-rendering: optimizeLegibility;
  font-weight: bold;
}
.start-teaser .grid-layout.-gutter > .item.infos > .description a:hover {
  color: #6b6b6b;
}
.start-teaser .grid-layout.-gutter > .item.infos .link-button:hover {
  color: #009a5b;
}
.start-teaser .grid-layout.-gutter > .item.infos .link-button .svg-icon {
  width: 1.4375rem;
  height: 1.4375rem;
  color: #009a5b;
}
.start-teaser .grid-layout.-gutter > .item.infos .link-button .svg-icon.-right {
  transform: rotate(180deg);
}
.start-teaser .grid-layout.-gutter > .item.infos >.cart-component {
  display: inline-block;
  width: 150px;
}
.start-teaser .grid-layout.-gutter > .item.infos >.buttons >.button-general {
  margin-top: 1.875rem;
}
@media only screen and (max-width: 48rem) {
  .start-teaser .cover {
    text-align: center;
  }
  .start-teaser .cover .image-container .preview {
    display: inline-block;
  }
  .start-teaser .cover .image-container .image {
    width: 80%;
    display: inline-block;
    margin-bottom: 0.9375rem;
    box-shadow: 0px 2px 16px rgba(107,107,107,0.5);
    opacity: 0.5;
  }
  .start-teaser .grid-layout.-gutter > .item.infos {
    padding-left: 0px;
  }
}
.start-testimonialcontainer {
  margin-bottom: 3.75rem;
  background-color: #009fe3;
  text-align: center;
  position: relative;
  height: 21.875rem;
}
.start-testimonialcontainer .testimonial {
  opacity: 1;
  transition: opacity 1s;
}
.start-testimonialcontainer .testimonial .quote,
.start-testimonialcontainer .testimonial .autor {
  color: #fff;
}
.start-testimonialcontainer .testimonial .quote > p {
  padding-bottom: 0.625rem;
  display: inline;
  color: #fff;
}
.start-testimonialcontainer .testimonial.-hidden {
  display: none;
  opacity: 0;
}
.start-testimonialcontainer .start-pointnavigation .point {
  border: 1px solid #66c5ef;
  background-color: #66c5ef;
}
.start-testimonialcontainer .start-pointnavigation .point.-active {
  background-color: #fff;
}
@media only screen and (max-width: 48rem) {
  .start-testimonialcontainer {
    margin-bottom: 3.125rem;
    height: 25rem;
  }
  .start-testimonialcontainer .testimonial .quote > p {
    font-family: 'Glober-Bold';
    font-size: 1.625rem;
    line-height: 2.125rem;
  }
}
.start-zikzakteaser {
  margin-bottom: 3.75rem;
}
.start-zikzakteaser .text {
  margin-bottom: 1.875rem;
}
.start-zikzakteaser .image {
  width: 100%;
}
.start-zikzakteaser .svg-icon {
  color: #009a5b;
  width: 30px;
  height: 30px;
}
.start-zikzakteaser .point {
  line-height: 0;
}
@media only screen and (max-width: 32.5rem) {
  .start-zikzakteaser {
    margin-bottom: 2.5rem;
  }
}
.ie-fixMinHeight {
  display: flex;
}
.ie-fixMinHeight .page {
  min-height: 100vh;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.ie-fixMinHeight .page .content {
  flex-grow: 1;
}
.task-alert {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 200;
  background-color: rgba(0,0,0,0.7);
}
.task-alert .content {
  background-color: #fff;
  width: 50%;
  padding: 4rem;
  position: absolute;
  left: 25vw;
  top: 100px;
}
.task-alert .content .button,
.task-alert .content .message {
  text-align: center;
}
@media only screen and (max-width: 32.5rem) {
  .task-alert .content {
    top: 50px;
    width: 90%;
    left: 5vw;
    padding: 2rem;
  }
}
.task-container {
  padding-top: 4.375rem;
  width: 100%;
  background-color: #dcecdc;
  padding-top: 5.625rem;
  padding-right: 8.75rem;
  padding-bottom: 3.75rem;
  padding-left: 8.75rem;
  margin-bottom: 5rem;
}
.task-container .task-form {
  margin-top: 7.5rem;
}
.task-container .close {
  cursor: pointer;
  position: absolute;
  top: 5.9375rem;
  right: 1.5625rem;
}
.task-container .close > .svg-icon {
  width: 2.5rem;
  height: 2.5rem;
}
.task-container .share {
  display: inline-block;
  margin-top: 1.875rem;
}
.task-container .description,
.task-container .label {
  margin-top: 2.5rem;
  display: block;
}
.task-container .task-title,
.task-container .task-description {
  border: none;
  padding: 0.9375rem;
  width: 100%;
}
.task-container .inputlabel {
  padding-bottom: 0.3125rem;
}
@media only screen and (max-width: 48rem) {
  .task-container {
    padding: 1.25rem 0.9375rem 2.5rem 0.9375rem;
  }
  .task-container .description {
    margin-top: 3.75rem;
  }
  .task-container .label {
    margin-top: 1.875rem;
  }
  .task-container .share {
    display: table;
    margin: 0.625rem auto;
  }
}
.task-link {
  margin: 0.625rem 0;
  display: flex;
  background-color: #f8f8f8;
}
.task-link > a {
  color: #009a5b;
}
.task-link .button {
  text-align: right;
}
.task-link .copy {
  display: inline;
}
.task-link .copy-link {
  width: 1rem;
  height: 1rem;
  margin-right: 0.625rem;
}
.task-menu >.add {
  cursor: pointer;
}
.task-menu >.add.-disabled {
  color: #808080;
  cursor: auto;
}
.task-response {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0,0,0,0.7);
  padding: 6.25rem;
}
.task-response a.break-text {
  color: #000;
}
.task-response >.content {
  background-color: #fff;
  padding: 1.25rem;
  position: relative;
}
.task-response >.content >.close {
  top: 1rem;
  right: 1rem;
}
@media only screen and (max-width: 48rem) {
  .task-response .task-link .svg-icon {
    display: none;
  }
}
@media only screen and (max-width: 32.5rem) {
  .task-response {
    padding: 1.25rem;
  }
  .task-response .content .task-link {
    background-color: #fff;
  }
  .task-response .content .task-link .button {
    text-align: center;
  }
  .task-response .content .task-link .svg-icon,
  .task-response .content .task-link .break-text {
    display: none;
  }
}
.teacher-comment {
  display: flex;
  padding-top: 1.875rem;
}
.teacher-comment > .download {
  display: flex;
}
.teacher-comment > .download > svg {
  margin-right: 1rem;
}
.unit-infobox {
  padding: 0.9375rem;
  height: 12.625rem;
}
.unit-infobox > .meta {
  padding-bottom: 0.9375rem;
  align-items: center;
}
.unit-infobox > .meta >.slugcode {
  text-align: right;
  color: #e94d51;
}
.unit-infobox > .meta >.slugcode >.number {
  color: #000;
}
.unit-infobox > .meta >.pagenumber {
  text-align: right;
  color: #6b6b6b;
}
.unit-levelfilter >.description {
  margin-bottom: 0.625rem;
}
.unit-levelfilter >.description a {
  color: #009a5b;
}
.unit-levelfilter >.description a:hover {
  color: #26262b;
}
.unit-levelfilter .filter {
  cursor: pointer;
}
.unit-levelfilter .filter:hover {
  color: #009a5b;
}
.unit-levelfilter .filter.-active >.item {
  border-bottom: 2px solid #009a5b;
}
@media only screen and (max-width: 48rem) {
  .unit-levelfilter {
    display: none;
  }
}
.unit-menu {
  display: inline-grid;
  justify-content: end;
}
@media only screen and (max-width: 48rem) {
  .unit-menu {
    justify-content: center;
  }
}
.unit-page {
  margin-bottom: 7.5rem;
}
.unit-preview {
  box-shadow: 0 2px 6px 0 rgba(107,107,107,0.2);
  border-radius: 2px;
  transition: transform 0.2s, box-shadow 0.2s;
  width: 319px;
  position: relative;
}
.unit-preview:hover {
  box-shadow: 0 2px 16px 1px rgba(107,107,107,0.3);
}
.unit-preview:hover.-disabled {
  box-shadow: 0 2px 6px 0 rgba(107,107,107,0.2);
  transform: none;
}
.unit-preview.-enabled {
  cursor: pointer;
}
.unit-preview.-disabled > .image-container,
.unit-preview.-disabled > .download,
.unit-preview.-disabled > .unit-infobox {
  opacity: 0.2;
}
.unit-preview.-selected {
  border: 2px solid #009a5b;
}
.unit-preview >.image-container {
  position: relative;
}
.unit-preview >.image-container > .image {
  height: 11.25rem;
  background-size: cover;
  background-position: center;
}
.unit-preview >.image-container >.subject {
  width: 50%;
  text-align: center;
  background-color: #008000;
  transform: translate(50%, -50%);
}
.unit-preview > .download {
  padding: 0.625rem 0.9375rem;
  display: flex;
  align-items: center;
  border-top: 1px solid #f8f8f8;
}
.unit-preview > .download.-disabled {
  cursor: default;
}
.unit-preview > .download.-empty {
  border-top: none;
  min-height: 2.8125rem;
  max-height: 2.8125rem;
}
.unit-preview > .download > a {
  display: flex;
  align-items: center;
}
.unit-preview >.login-required {
  display: none;
}
.unit-preview >.license-required,
.unit-preview.-disabled.-auth >.login-required {
  display: none;
}
.unit-preview.-disabled >.login-required,
.unit-preview.-disabled.-auth >.license-required {
  display: block;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 1.25rem 0;
  background-color: #fff;
  text-align: center;
}
.unit-preview.-disabled >.login-required >.text,
.unit-preview.-disabled.-auth >.license-required >.text {
  margin-bottom: 0.9375rem;
}
@media only screen and (max-width: 61.25rem) {
  .unit-preview {
    width: 100%;
  }
}
@media only screen and (max-width: 48rem) {
  .unit-preview {
    width: 100%;
  }
}
.unit-teacherinfo {
  padding: 3.75rem 0;
  background-color: #f2f8fd;
  margin-top: 3.75rem;
}
.unit-teacherinfo >.toggle {
  margin: 0 1.25rem;
  padding: 0.625rem 0;
  border-bottom: 1px solid #26262b;
}
.unit-teacherinfo > .content {
  padding: 1.25rem;
}
.unit-teacherinfo > .content > .teacher-download {
  display: flex;
}
.unit-type {
  padding-right: 0.9375rem;
}
.unit-type > svg {
  width: 2.1875rem;
  height: 2.1875rem;
}
.unitpage-header {
  padding-bottom: 1.875rem;
  padding-top: 5.625rem;
}
.unitpage-header > .unittype {
  display: flex;
  align-items: center;
  padding-right: 0.9375rem;
  padding-top: 3.75rem;
}
.unitpage-header > .unittype >.slugcode {
  color: #e94d51;
  font-family: 'Glober-Extra-Bold';
}
.unitpage-header > .unittype >.slugcode >.number {
  color: #000;
}
.unitpage-header > .unittype .pagenumber {
  color: #6b6b6b;
}
.unitpage-header > .unittype > svg {
  width: 3.75rem;
  height: 3.75rem;
}
.unitpage-header > .title {
  padding: 1.875rem 0;
}
.unitpage-header > .meta {
  padding: 1.375rem 0;
  margin-bottom: 1.875rem;
  border-top: 1px solid #9c9b9b;
  border-bottom: 1px solid #9c9b9b;
}
.unitpage-header > .description {
  padding-bottom: 1.875rem;
}
@media only screen and (max-width: 48rem) {
  .unitpage-header {
    padding-top: 3.75rem;
  }
}
.unitpage-metacontainer {
  padding: 1.375rem 0;
  margin-bottom: 1.875rem;
  border-top: 1px solid #9c9b9b;
  border-bottom: 1px solid #9c9b9b;
}
.unitpage-metacontainer > .levelbox-container {
  padding-right: 1.875rem;
  display: flex;
  align-items: center;
}
.unitpage-metacontainer > .slug {
  margin-right: 1.875rem;
  border: 2px solid #009fe3;
  padding: 0px 5px;
  border-radius: 5px 15px 5px 5px;
}
.unitpage-metacontainer > .duration {
  display: flex;
  padding-right: 1.875rem;
}
.unitpage-metacontainer > .duration > svg {
  margin-right: 0.9375rem;
}
.unitpage-metacontainer > .competences {
  display: flex;
}
.unitpage-metacontainer > .competences > .competence {
  display: flex;
  margin-right: 1.5625rem;
}
.unitpage-metacontainer > .competences > .competence > svg {
  margin-right: 0.9375rem;
}
.unitpage-metacontainer > .competences > .competence > a {
  color: #6b6b6b;
}
.unitpage-metacontainer > .competences > .competence:hover > a {
  color: #009a5b;
}
@media only screen and (max-width: 48rem) {
  .unitpage-metacontainer {
    display: block;
  }
  .unitpage-metacontainer >.item {
    margin-bottom: 1rem;
  }
}
.zikzak-logo-white {
  display: flex;
}
.zikzak-logo-white > img {
  height: 3.125rem;
}
.zikzak-logo {
  display: flex;
}
.zikzak-logo > img {
  height: 3.125rem;
}
.hidden {
  display: none;
  visibility: hidden;
}
.break-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}
.inline {
  display: inline-block;
}
svg path {
  fill: inherit;
}
html,
body {
  height: 100%;
  position: relative;
}
