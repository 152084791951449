.unit-menu {

    display: inline-grid;
    justify-content: end;

}



@media only screen and (max-width: theme.settings.breakpoints.small) { 
    .unit-menu {
        //margin-bottom: rem(30px);
        justify-content: center;
    }
}