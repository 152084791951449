.hidden {
    display: none;
    visibility: hidden;
}

.break-text {
  
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.inline {
  display: inline-block;
}