.footer-component {
    
    color: theme.colors.white;
    padding: rem(30px) rem(140px);
    // margin-top: rem(120px);
    background-color: theme.colors.black;

    .mailto, .impressum, .phone {
        color: white;
        &:hover {
            text-decoration:underline;
        }
    }




    @media only screen and (max-width: theme.settings.breakpoints.small) {

        padding: rem(30px) rem(70px);

        .-location, .-phone, .-mail, .impressum, .footer-heading {
            display: block;
            text-align: center;
        }

        .logo {
            display: flex;
            justify-content: center;
        }

        .-spacer {
            display: none;
        }

        .-phone, .-mail, .-impressum {
            padding-bottom: rem(20px);
        }


    }

}