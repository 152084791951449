.unit-teacherinfo {

    padding: rem(60px) 0;
    background-color: theme.colors.background-blue;
    margin-top: rem(60px);

    >.toggle {
             margin: 0 rem(20px);
             padding: rem(10px) 0;
             border-bottom: 1px solid theme.colors.black;
    }

    > .content {

        padding: rem(20px);

        > .teacher-download {

            display: flex;
            
        }

    }

}