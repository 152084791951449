.navigation-link {
    
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    > .icon {
        display: flex;
    }

    > .icon svg {
        color:theme.colors.primary;
        width: rem(30px);
        height: rem(30px);
        //to avoid element selection on mobile
        &.-open, &.-close {
            margin: rem(10px);
        }
    }


    > .text {
        margin-left: rem(15px);
    }

    &:hover {
        .text {
            color: theme.colors.primary;
        }
    }
    
    .svg-icon.-inline.-close {
        display:none;
    }

    &.-open {
        .svg-icon.-inline.-close {
            display:inline-block;
        }
        .svg-icon.-inline.-open {
            display:none;
        }
    }

    &.-active >.text {
        //weil fredi das schön findet:
        border-bottom: 2px solid theme.colors.primary;
        color:theme.colors.primary;
    }
}