.task-container {

    // position: relative;
    //top: rem(70px);
    // width: 100vw;
    padding-top: rem(70px);
    width: 100%;
    background-color: theme.colors.background-green;

    

    padding-top: rem(90px);
    padding-right: rem(140px);
    padding-bottom: rem(60px);
    padding-left: rem(140px);
    margin-bottom: rem(80px);

    .task-form {
        margin-top: rem(120px);
    }
    .close {

        cursor: pointer;
        position: absolute;
        top: rem(95px);
        right: rem(25px);

        > .svg-icon {
            width: rem(40px);
            height: rem(40px);
        }

    }

    .share {

        display: inline-block;
        margin-top: rem(30px);

    }

    .description, .label {
        margin-top: rem(40px);
        display: block;

    }

    .task-title, .task-description {

        border: none;
        padding: rem(15px);
        width: 100%;

    }

    .inputlabel {

        padding-bottom: rem(5px);

    }

    @media only screen and (max-width: theme.settings.breakpoints.small) {
        

        padding: rem(20px) rem(15px) rem(40px) rem(15px);

        .description {
            margin-top: rem(60px);
        }

        .label {
            margin-top: rem(30px);
        }

        .share {
            display: table;
            margin: rem(10px) auto;
        }

    }

}