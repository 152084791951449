.task-link {

    
    margin: rem(10px) 0;
    display:flex;
    background-color: theme.colors.gray-3;
    > a {
        color: theme.colors.primary;
    }
    .button {
        text-align: right;
    }
    .copy {
        display: inline;
    }

    .copy-link {
        width: rem(16px);
        height: rem(16px);
        margin-right: rem(10px);
        // cursor:pointer;
        
    }
}