.paragraph-element {

    padding-bottom: rem(60px);

    ul, ol {
        >li {
            padding: rem(5px);
        }


    }

}