.navigation-unit {
    
    background-color: white;
    padding: rem(10px) rem(30px) rem(10px) rem(30px);
    box-shadow: 0 2px 8px 0 rgba(38,38,43,0.10);

    z-index: 10;
    .-right {

        text-align:right;
        
    }

    .back {
        width: min-content;
    }

    .stupidIE {
        display: -ms-grid;
        -ms-grid-columns: min-content;
    }

}