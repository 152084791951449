.feedback-box {

    > .feedback-panel {

        display: flex;
        align-items: center;

        > .icon {
            display: inline-block;
            min-width: rem(40px);
            height: rem(40px);
            margin-right: rem(20px);
        }

    }


    &.-wrong {

        &.select {
            border: 1px solid theme.colors.wrong;
            background-color: theme.colors.wrong-background;
        }

        > .answer-container {
            > .text {
                color: theme.colors.wrong;
                
            }
        }

        > .feedback-panel {

            > .icon {
                background-image: url('../images/icon-wrong.svg');
            }

        }

    }

    &.-correct {

        &.select {
            border: 1px solid theme.colors.green;
            background-color:theme.colors.correct-background;
        }
        

        > .answer-container {
            > .text {
                color: theme.colors.green;
                
            }
        }

        > .feedback-panel {

            > .icon {
                background-image: url('../images/icon-correct.svg');
            }

        }

    }

}